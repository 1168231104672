// @ts-nocheck
// This file is autogenerated by scripts/build-registry.ts
// Do not edit this file directly.
import * as React from "react"

export const Index: Record<string, any> = {
  "default": {
    "accordion": {
      name: "accordion",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/accordion")),
      source: "",
      files: ["registry/default/ui/accordion.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert": {
      name: "alert",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/alert")),
      source: "",
      files: ["registry/default/ui/alert.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-dialog": {
      name: "alert-dialog",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/ui/alert-dialog")),
      source: "",
      files: ["registry/default/ui/alert-dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam": {
      name: "animated-beam",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/animated-beam")),
      source: "",
      files: ["registry/default/ui/animated-beam.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-grid-pattern": {
      name: "animated-grid-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/animated-grid-pattern")),
      source: "",
      files: ["registry/default/ui/animated-grid-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-lines": {
      name: "animated-lines",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/animated-lines")),
      source: "",
      files: ["registry/default/ui/animated-lines.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-list": {
      name: "animated-list",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/animated-list")),
      source: "",
      files: ["registry/default/ui/animated-list.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-shiny-text": {
      name: "animated-shiny-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/animated-shiny-text")),
      source: "",
      files: ["registry/default/ui/animated-shiny-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-subscribe-button": {
      name: "animated-subscribe-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/animated-subscribe-button")),
      source: "",
      files: ["registry/default/ui/animated-subscribe-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-gradient-text": {
      name: "animated-gradient-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/animated-gradient-text")),
      source: "",
      files: ["registry/default/ui/animated-gradient-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "aspect-ratio": {
      name: "aspect-ratio",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/aspect-ratio")),
      source: "",
      files: ["registry/default/ui/aspect-ratio.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar": {
      name: "avatar",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/avatar")),
      source: "",
      files: ["registry/default/ui/avatar.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar-circles": {
      name: "avatar-circles",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/avatar-circles")),
      source: "",
      files: ["registry/default/ui/avatar-circles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge": {
      name: "badge",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/badge")),
      source: "",
      files: ["registry/default/ui/badge.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "bento-grid": {
      name: "bento-grid",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/ui/bento-grid")),
      source: "",
      files: ["registry/default/ui/bento-grid.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-in": {
      name: "blur-in",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/blur-in")),
      source: "",
      files: ["registry/default/ui/blur-in.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "border-beam": {
      name: "border-beam",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/border-beam")),
      source: "",
      files: ["registry/default/ui/border-beam.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "box-reveal": {
      name: "box-reveal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/box-reveal")),
      source: "",
      files: ["registry/default/ui/box-reveal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb": {
      name: "breadcrumb",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/breadcrumb")),
      source: "",
      files: ["registry/default/ui/breadcrumb.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button": {
      name: "button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/button")),
      source: "",
      files: ["registry/default/ui/button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-fade": {
      name: "blur-fade",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/blur-fade")),
      source: "",
      files: ["registry/default/ui/blur-fade.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "calendar": {
      name: "calendar",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/ui/calendar")),
      source: "",
      files: ["registry/default/ui/calendar.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "card": {
      name: "card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/card")),
      source: "",
      files: ["registry/default/ui/card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel": {
      name: "carousel",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/ui/carousel")),
      source: "",
      files: ["registry/default/ui/carousel.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart": {
      name: "chart",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/chart")),
      source: "",
      files: ["registry/default/ui/chart.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox": {
      name: "checkbox",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/checkbox")),
      source: "",
      files: ["registry/default/ui/checkbox.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "client-tweet-card": {
      name: "client-tweet-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/client-tweet-card")),
      source: "",
      files: ["registry/default/ui/client-tweet-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "collapsible": {
      name: "collapsible",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/collapsible")),
      source: "",
      files: ["registry/default/ui/collapsible.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "command": {
      name: "command",
      type: "components:ui",
      registryDependencies: ["dialog"],
      component: React.lazy(() => import("~/registry/default/ui/command")),
      source: "",
      files: ["registry/default/ui/command.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti": {
      name: "confetti",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/confetti")),
      source: "",
      files: ["registry/default/ui/confetti.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "context-menu": {
      name: "context-menu",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/context-menu")),
      source: "",
      files: ["registry/default/ui/context-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cool-mode": {
      name: "cool-mode",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/cool-mode")),
      source: "",
      files: ["registry/default/ui/cool-mode.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dialog": {
      name: "dialog",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/dialog")),
      source: "",
      files: ["registry/default/ui/dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dock": {
      name: "dock",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/dock")),
      source: "",
      files: ["registry/default/ui/dock.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dot-pattern": {
      name: "dot-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/dot-pattern")),
      source: "",
      files: ["registry/default/ui/dot-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "drawer": {
      name: "drawer",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/drawer")),
      source: "",
      files: ["registry/default/ui/drawer.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu": {
      name: "dropdown-menu",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/dropdown-menu")),
      source: "",
      files: ["registry/default/ui/dropdown-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "fade-text": {
      name: "fade-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/fade-text")),
      source: "",
      files: ["registry/default/ui/fade-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "file-tree": {
      name: "file-tree",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/file-tree")),
      source: "",
      files: ["registry/default/ui/file-tree.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "flip-text": {
      name: "flip-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/flip-text")),
      source: "",
      files: ["registry/default/ui/flip-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "form": {
      name: "form",
      type: "components:ui",
      registryDependencies: ["button","label"],
      component: React.lazy(() => import("~/registry/default/ui/form")),
      source: "",
      files: ["registry/default/ui/form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "globe": {
      name: "globe",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/globe")),
      source: "",
      files: ["registry/default/ui/globe.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gradual-spacing": {
      name: "gradual-spacing",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/gradual-spacing")),
      source: "",
      files: ["registry/default/ui/gradual-spacing.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern": {
      name: "grid-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/grid-pattern")),
      source: "",
      files: ["registry/default/ui/grid-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gauge-circle": {
      name: "gauge-circle",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/gauge-circle")),
      source: "",
      files: ["registry/default/ui/gauge-circle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hero-video": {
      name: "hero-video",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/hero-video")),
      source: "",
      files: ["registry/default/ui/hero-video.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hover-card": {
      name: "hover-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/hover-card")),
      source: "",
      files: ["registry/default/ui/hover-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "icon-cloud": {
      name: "icon-cloud",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/icon-cloud")),
      source: "",
      files: ["registry/default/ui/icon-cloud.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input": {
      name: "input",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/input")),
      source: "",
      files: ["registry/default/ui/input.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp": {
      name: "input-otp",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/input-otp")),
      source: "",
      files: ["registry/default/ui/input-otp.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone": {
      name: "input-phone",
      type: "components:ui",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/default/ui/input-phone")),
      source: "",
      files: ["registry/default/ui/input-phone.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "label": {
      name: "label",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/label")),
      source: "",
      files: ["registry/default/ui/label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "letter-pullup": {
      name: "letter-pullup",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/letter-pullup")),
      source: "",
      files: ["registry/default/ui/letter-pullup.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "linear-gradient": {
      name: "linear-gradient",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/linear-gradient")),
      source: "",
      files: ["registry/default/ui/linear-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "magic-card": {
      name: "magic-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/magic-card")),
      source: "",
      files: ["registry/default/ui/magic-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee": {
      name: "marquee",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/marquee")),
      source: "",
      files: ["registry/default/ui/marquee.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "menubar": {
      name: "menubar",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/menubar")),
      source: "",
      files: ["registry/default/ui/menubar.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "meteors": {
      name: "meteors",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/meteors")),
      source: "",
      files: ["registry/default/ui/meteors.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select": {
      name: "multi-select",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/multi-select")),
      source: "",
      files: ["registry/default/ui/multi-select.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "navigation-menu": {
      name: "navigation-menu",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/navigation-menu")),
      source: "",
      files: ["registry/default/ui/navigation-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "neon-gradient-card": {
      name: "neon-gradient-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/neon-gradient-card")),
      source: "",
      files: ["registry/default/ui/neon-gradient-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "number-ticker": {
      name: "number-ticker",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/number-ticker")),
      source: "",
      files: ["registry/default/ui/number-ticker.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "orbiting-circles": {
      name: "orbiting-circles",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/orbiting-circles")),
      source: "",
      files: ["registry/default/ui/orbiting-circles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pagination": {
      name: "pagination",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/ui/pagination")),
      source: "",
      files: ["registry/default/ui/pagination.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "particles": {
      name: "particles",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/particles")),
      source: "",
      files: ["registry/default/ui/particles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "popover": {
      name: "popover",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/popover")),
      source: "",
      files: ["registry/default/ui/popover.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "progress": {
      name: "progress",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/progress")),
      source: "",
      files: ["registry/default/ui/progress.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pulsating-button": {
      name: "pulsating-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/pulsating-button")),
      source: "",
      files: ["registry/default/ui/pulsating-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radio-group": {
      name: "radio-group",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/radio-group")),
      source: "",
      files: ["registry/default/ui/radio-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radial-gradient": {
      name: "radial-gradient",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/radial-gradient")),
      source: "",
      files: ["registry/default/ui/radial-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable": {
      name: "resizable",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/resizable")),
      source: "",
      files: ["registry/default/ui/resizable.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "retro-grid": {
      name: "retro-grid",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/retro-grid")),
      source: "",
      files: ["registry/default/ui/retro-grid.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "ripple": {
      name: "ripple",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/ripple")),
      source: "",
      files: ["registry/default/ui/ripple.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-area": {
      name: "scroll-area",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/scroll-area")),
      source: "",
      files: ["registry/default/ui/scroll-area.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-based-velocity": {
      name: "scroll-based-velocity",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/scroll-based-velocity")),
      source: "",
      files: ["registry/default/ui/scroll-based-velocity.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-progress": {
      name: "scroll-progress",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/scroll-progress")),
      source: "",
      files: ["registry/default/ui/scroll-progress.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select": {
      name: "select",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/select")),
      source: "",
      files: ["registry/default/ui/select.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separate-away": {
      name: "separate-away",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/separate-away")),
      source: "",
      files: ["registry/default/ui/separate-away.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separator": {
      name: "separator",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/separator")),
      source: "",
      files: ["registry/default/ui/separator.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sheet": {
      name: "sheet",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/sheet")),
      source: "",
      files: ["registry/default/ui/sheet.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shimmer-button": {
      name: "shimmer-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/shimmer-button")),
      source: "",
      files: ["registry/default/ui/shimmer-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shine-border": {
      name: "shine-border",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/shine-border")),
      source: "",
      files: ["registry/default/ui/shine-border.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shiny-button": {
      name: "shiny-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/shiny-button")),
      source: "",
      files: ["registry/default/ui/shiny-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "skeleton": {
      name: "skeleton",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/skeleton")),
      source: "",
      files: ["registry/default/ui/skeleton.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider": {
      name: "slider",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/slider")),
      source: "",
      files: ["registry/default/ui/slider.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sonner": {
      name: "sonner",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/sonner")),
      source: "",
      files: ["registry/default/ui/sonner.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sparkles-text": {
      name: "sparkles-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/sparkles-text")),
      source: "",
      files: ["registry/default/ui/sparkles-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating": {
      name: "star-rating",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/star-rating")),
      source: "",
      files: ["registry/default/ui/star-rating.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "switch": {
      name: "switch",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/switch")),
      source: "",
      files: ["registry/default/ui/switch.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper": {
      name: "stepper",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/stepper")),
      source: "",
      files: ["registry/default/ui/stepper.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scratch-to-reveal": {
      name: "scratch-to-reveal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/scratch-to-reveal")),
      source: "",
      files: ["registry/default/ui/scratch-to-reveal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "table": {
      name: "table",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/table")),
      source: "",
      files: ["registry/default/ui/table.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tabs": {
      name: "tabs",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/tabs")),
      source: "",
      files: ["registry/default/ui/tabs.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea": {
      name: "textarea",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/textarea")),
      source: "",
      files: ["registry/default/ui/textarea.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "text-reveal": {
      name: "text-reveal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/text-reveal")),
      source: "",
      files: ["registry/default/ui/text-reveal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline": {
      name: "timeline",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/timeline")),
      source: "",
      files: ["registry/default/ui/timeline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc": {
      name: "toc",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/toc")),
      source: "",
      files: ["registry/default/ui/toc.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast": {
      name: "toast",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/toast")),
      source: "",
      files: ["registry/default/ui/toast.tsx","registry/default/ui/use-toast.ts","registry/default/ui/toaster.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle": {
      name: "toggle",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/toggle")),
      source: "",
      files: ["registry/default/ui/toggle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group": {
      name: "toggle-group",
      type: "components:ui",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/default/ui/toggle-group")),
      source: "",
      files: ["registry/default/ui/toggle-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip": {
      name: "tooltip",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/tooltip")),
      source: "",
      files: ["registry/default/ui/tooltip.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tour": {
      name: "tour",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/tour")),
      source: "",
      files: ["registry/default/ui/tour.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typing-animation": {
      name: "typing-animation",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/typing-animation")),
      source: "",
      files: ["registry/default/ui/typing-animation.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card": {
      name: "tweet-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/tweet-card")),
      source: "",
      files: ["registry/default/ui/tweet-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "video-modal": {
      name: "video-modal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/video-modal")),
      source: "",
      files: ["registry/default/ui/video-modal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-dot-pattern": {
      name: "wavy-dot-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/wavy-dot-pattern")),
      source: "",
      files: ["registry/default/ui/wavy-dot-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-text": {
      name: "wavy-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/wavy-text")),
      source: "",
      files: ["registry/default/ui/wavy-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-fade-in": {
      name: "word-fade-in",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/word-fade-in")),
      source: "",
      files: ["registry/default/ui/word-fade-in.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-pull-up": {
      name: "word-pull-up",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/word-pull-up")),
      source: "",
      files: ["registry/default/ui/word-pull-up.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-rotate": {
      name: "word-rotate",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/ui/word-rotate")),
      source: "",
      files: ["registry/default/ui/word-rotate.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "accordion-demo": {
      name: "accordion-demo",
      type: "components:example",
      registryDependencies: ["accordion"],
      component: React.lazy(() => import("~/registry/default/example/accordion-demo")),
      source: "",
      files: ["registry/default/example/accordion-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-demo": {
      name: "alert-demo",
      type: "components:example",
      registryDependencies: ["alert"],
      component: React.lazy(() => import("~/registry/default/example/alert-demo")),
      source: "",
      files: ["registry/default/example/alert-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-destructive": {
      name: "alert-destructive",
      type: "components:example",
      registryDependencies: ["alert"],
      component: React.lazy(() => import("~/registry/default/example/alert-destructive")),
      source: "",
      files: ["registry/default/example/alert-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-dialog-demo": {
      name: "alert-dialog-demo",
      type: "components:example",
      registryDependencies: ["alert-dialog","button"],
      component: React.lazy(() => import("~/registry/default/example/alert-dialog-demo")),
      source: "",
      files: ["registry/default/example/alert-dialog-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "aspect-ratio-demo": {
      name: "aspect-ratio-demo",
      type: "components:example",
      registryDependencies: ["aspect-ratio"],
      component: React.lazy(() => import("~/registry/default/example/aspect-ratio-demo")),
      source: "",
      files: ["registry/default/example/aspect-ratio-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar-demo": {
      name: "avatar-demo",
      type: "components:example",
      registryDependencies: ["avatar"],
      component: React.lazy(() => import("~/registry/default/example/avatar-demo")),
      source: "",
      files: ["registry/default/example/avatar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-demo": {
      name: "badge-demo",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/default/example/badge-demo")),
      source: "",
      files: ["registry/default/example/badge-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-destructive": {
      name: "badge-destructive",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/default/example/badge-destructive")),
      source: "",
      files: ["registry/default/example/badge-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-outline": {
      name: "badge-outline",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/default/example/badge-outline")),
      source: "",
      files: ["registry/default/example/badge-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-secondary": {
      name: "badge-secondary",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/default/example/badge-secondary")),
      source: "",
      files: ["registry/default/example/badge-secondary.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-demo": {
      name: "breadcrumb-demo",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/default/example/breadcrumb-demo")),
      source: "",
      files: ["registry/default/example/breadcrumb-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-separator": {
      name: "breadcrumb-separator",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/default/example/breadcrumb-separator")),
      source: "",
      files: ["registry/default/example/breadcrumb-separator.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-dropdown": {
      name: "breadcrumb-dropdown",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/default/example/breadcrumb-dropdown")),
      source: "",
      files: ["registry/default/example/breadcrumb-dropdown.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-ellipsis": {
      name: "breadcrumb-ellipsis",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/default/example/breadcrumb-ellipsis")),
      source: "",
      files: ["registry/default/example/breadcrumb-ellipsis.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-link": {
      name: "breadcrumb-link",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/default/example/breadcrumb-link")),
      source: "",
      files: ["registry/default/example/breadcrumb-link.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-responsive": {
      name: "breadcrumb-responsive",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/default/example/breadcrumb-responsive")),
      source: "",
      files: ["registry/default/example/breadcrumb-responsive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-demo": {
      name: "button-demo",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-demo")),
      source: "",
      files: ["registry/default/example/button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-secondary": {
      name: "button-secondary",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-secondary")),
      source: "",
      files: ["registry/default/example/button-secondary.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-destructive": {
      name: "button-destructive",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-destructive")),
      source: "",
      files: ["registry/default/example/button-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-outline": {
      name: "button-outline",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-outline")),
      source: "",
      files: ["registry/default/example/button-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-ghost": {
      name: "button-ghost",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-ghost")),
      source: "",
      files: ["registry/default/example/button-ghost.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-link": {
      name: "button-link",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-link")),
      source: "",
      files: ["registry/default/example/button-link.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-with-icon": {
      name: "button-with-icon",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-with-icon")),
      source: "",
      files: ["registry/default/example/button-with-icon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-loading": {
      name: "button-loading",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-loading")),
      source: "",
      files: ["registry/default/example/button-loading.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-icon": {
      name: "button-icon",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-icon")),
      source: "",
      files: ["registry/default/example/button-icon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-as-child": {
      name: "button-as-child",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/default/example/button-as-child")),
      source: "",
      files: ["registry/default/example/button-as-child.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pulsating-button-demo": {
      name: "pulsating-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/pulsating-button-demo")),
      source: "",
      files: ["registry/default/example/pulsating-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "calendar-demo": {
      name: "calendar-demo",
      type: "components:example",
      registryDependencies: ["calendar"],
      component: React.lazy(() => import("~/registry/default/example/calendar-demo")),
      source: "",
      files: ["registry/default/example/calendar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "calendar-form": {
      name: "calendar-form",
      type: "components:example",
      registryDependencies: ["calendar","form","popover"],
      component: React.lazy(() => import("~/registry/default/example/calendar-form")),
      source: "",
      files: ["registry/default/example/calendar-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "card-demo": {
      name: "card-demo",
      type: "components:example",
      registryDependencies: ["card","button","switch"],
      component: React.lazy(() => import("~/registry/default/example/card-demo")),
      source: "",
      files: ["registry/default/example/card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "card-with-form": {
      name: "card-with-form",
      type: "components:example",
      registryDependencies: ["button","card","input","label","select"],
      component: React.lazy(() => import("~/registry/default/example/card-with-form")),
      source: "",
      files: ["registry/default/example/card-with-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-demo": {
      name: "carousel-demo",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/default/example/carousel-demo")),
      source: "",
      files: ["registry/default/example/carousel-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-size": {
      name: "carousel-size",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/default/example/carousel-size")),
      source: "",
      files: ["registry/default/example/carousel-size.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-spacing": {
      name: "carousel-spacing",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/default/example/carousel-spacing")),
      source: "",
      files: ["registry/default/example/carousel-spacing.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-orientation": {
      name: "carousel-orientation",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/default/example/carousel-orientation")),
      source: "",
      files: ["registry/default/example/carousel-orientation.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-dots": {
      name: "carousel-dots",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/default/example/carousel-dots")),
      source: "",
      files: ["registry/default/example/carousel-dots.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-api": {
      name: "carousel-api",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/default/example/carousel-api")),
      source: "",
      files: ["registry/default/example/carousel-api.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-plugin": {
      name: "carousel-plugin",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/default/example/carousel-plugin")),
      source: "",
      files: ["registry/default/example/carousel-plugin.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-demo": {
      name: "checkbox-demo",
      type: "components:example",
      registryDependencies: ["checkbox"],
      component: React.lazy(() => import("~/registry/default/example/checkbox-demo")),
      source: "",
      files: ["registry/default/example/checkbox-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-disabled": {
      name: "checkbox-disabled",
      type: "components:example",
      registryDependencies: ["checkbox"],
      component: React.lazy(() => import("~/registry/default/example/checkbox-disabled")),
      source: "",
      files: ["registry/default/example/checkbox-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-form-multiple": {
      name: "checkbox-form-multiple",
      type: "components:example",
      registryDependencies: ["checkbox","form"],
      component: React.lazy(() => import("~/registry/default/example/checkbox-form-multiple")),
      source: "",
      files: ["registry/default/example/checkbox-form-multiple.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-form-single": {
      name: "checkbox-form-single",
      type: "components:example",
      registryDependencies: ["checkbox","form"],
      component: React.lazy(() => import("~/registry/default/example/checkbox-form-single")),
      source: "",
      files: ["registry/default/example/checkbox-form-single.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-with-text": {
      name: "checkbox-with-text",
      type: "components:example",
      registryDependencies: ["checkbox"],
      component: React.lazy(() => import("~/registry/default/example/checkbox-with-text")),
      source: "",
      files: ["registry/default/example/checkbox-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "collapsible-demo": {
      name: "collapsible-demo",
      type: "components:example",
      registryDependencies: ["collapsible"],
      component: React.lazy(() => import("~/registry/default/example/collapsible-demo")),
      source: "",
      files: ["registry/default/example/collapsible-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-demo": {
      name: "combobox-demo",
      type: "components:example",
      registryDependencies: ["command"],
      component: React.lazy(() => import("~/registry/default/example/combobox-demo")),
      source: "",
      files: ["registry/default/example/combobox-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-dropdown-menu": {
      name: "combobox-dropdown-menu",
      type: "components:example",
      registryDependencies: ["command","dropdown-menu","button"],
      component: React.lazy(() => import("~/registry/default/example/combobox-dropdown-menu")),
      source: "",
      files: ["registry/default/example/combobox-dropdown-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-form": {
      name: "combobox-form",
      type: "components:example",
      registryDependencies: ["command","form"],
      component: React.lazy(() => import("~/registry/default/example/combobox-form")),
      source: "",
      files: ["registry/default/example/combobox-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-popover": {
      name: "combobox-popover",
      type: "components:example",
      registryDependencies: ["combobox","popover"],
      component: React.lazy(() => import("~/registry/default/example/combobox-popover")),
      source: "",
      files: ["registry/default/example/combobox-popover.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-responsive": {
      name: "combobox-responsive",
      type: "components:example",
      registryDependencies: ["combobox","popover","drawer"],
      component: React.lazy(() => import("~/registry/default/example/combobox-responsive")),
      source: "",
      files: ["registry/default/example/combobox-responsive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "command-demo": {
      name: "command-demo",
      type: "components:example",
      registryDependencies: ["command"],
      component: React.lazy(() => import("~/registry/default/example/command-demo")),
      source: "",
      files: ["registry/default/example/command-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "command-dialog": {
      name: "command-dialog",
      type: "components:example",
      registryDependencies: ["command","dialog"],
      component: React.lazy(() => import("~/registry/default/example/command-dialog")),
      source: "",
      files: ["registry/default/example/command-dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "context-menu-demo": {
      name: "context-menu-demo",
      type: "components:example",
      registryDependencies: ["context-menu"],
      component: React.lazy(() => import("~/registry/default/example/context-menu-demo")),
      source: "",
      files: ["registry/default/example/context-menu-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "data-table-demo": {
      name: "data-table-demo",
      type: "components:example",
      registryDependencies: ["data-table"],
      component: React.lazy(() => import("~/registry/default/example/data-table-demo")),
      source: "",
      files: ["registry/default/example/data-table-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-demo": {
      name: "date-picker-demo",
      type: "components:example",
      registryDependencies: ["button","calendar","popover"],
      component: React.lazy(() => import("~/registry/default/example/date-picker-demo")),
      source: "",
      files: ["registry/default/example/date-picker-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-form": {
      name: "date-picker-form",
      type: "components:example",
      registryDependencies: ["button","calendar","form","popover"],
      component: React.lazy(() => import("~/registry/default/example/date-picker-form")),
      source: "",
      files: ["registry/default/example/date-picker-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-with-presets": {
      name: "date-picker-with-presets",
      type: "components:example",
      registryDependencies: ["button","calendar","popover","select"],
      component: React.lazy(() => import("~/registry/default/example/date-picker-with-presets")),
      source: "",
      files: ["registry/default/example/date-picker-with-presets.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-with-range": {
      name: "date-picker-with-range",
      type: "components:example",
      registryDependencies: ["button","calendar","popover"],
      component: React.lazy(() => import("~/registry/default/example/date-picker-with-range")),
      source: "",
      files: ["registry/default/example/date-picker-with-range.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dialog-demo": {
      name: "dialog-demo",
      type: "components:example",
      registryDependencies: ["dialog"],
      component: React.lazy(() => import("~/registry/default/example/dialog-demo")),
      source: "",
      files: ["registry/default/example/dialog-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dialog-close-button": {
      name: "dialog-close-button",
      type: "components:example",
      registryDependencies: ["dialog","button"],
      component: React.lazy(() => import("~/registry/default/example/dialog-close-button")),
      source: "",
      files: ["registry/default/example/dialog-close-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "drawer-demo": {
      name: "drawer-demo",
      type: "components:example",
      registryDependencies: ["drawer"],
      component: React.lazy(() => import("~/registry/default/example/drawer-demo")),
      source: "",
      files: ["registry/default/example/drawer-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "drawer-dialog": {
      name: "drawer-dialog",
      type: "components:example",
      registryDependencies: ["drawer","dialog"],
      component: React.lazy(() => import("~/registry/default/example/drawer-dialog")),
      source: "",
      files: ["registry/default/example/drawer-dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu-demo": {
      name: "dropdown-menu-demo",
      type: "components:example",
      registryDependencies: ["dropdown-menu"],
      component: React.lazy(() => import("~/registry/default/example/dropdown-menu-demo")),
      source: "",
      files: ["registry/default/example/dropdown-menu-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu-checkboxes": {
      name: "dropdown-menu-checkboxes",
      type: "components:example",
      registryDependencies: ["dropdown-menu","checkbox"],
      component: React.lazy(() => import("~/registry/default/example/dropdown-menu-checkboxes")),
      source: "",
      files: ["registry/default/example/dropdown-menu-checkboxes.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu-radio-group": {
      name: "dropdown-menu-radio-group",
      type: "components:example",
      registryDependencies: ["dropdown-menu","radio-group"],
      component: React.lazy(() => import("~/registry/default/example/dropdown-menu-radio-group")),
      source: "",
      files: ["registry/default/example/dropdown-menu-radio-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hover-card-demo": {
      name: "hover-card-demo",
      type: "components:example",
      registryDependencies: ["hover-card"],
      component: React.lazy(() => import("~/registry/default/example/hover-card-demo")),
      source: "",
      files: ["registry/default/example/hover-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-demo": {
      name: "input-demo",
      type: "components:example",
      registryDependencies: ["input"],
      component: React.lazy(() => import("~/registry/default/example/input-demo")),
      source: "",
      files: ["registry/default/example/input-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-disabled": {
      name: "input-disabled",
      type: "components:example",
      registryDependencies: ["input"],
      component: React.lazy(() => import("~/registry/default/example/input-disabled")),
      source: "",
      files: ["registry/default/example/input-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-file": {
      name: "input-file",
      type: "components:example",
      registryDependencies: ["input"],
      component: React.lazy(() => import("~/registry/default/example/input-file")),
      source: "",
      files: ["registry/default/example/input-file.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-form": {
      name: "input-form",
      type: "components:example",
      registryDependencies: ["input","button","form"],
      component: React.lazy(() => import("~/registry/default/example/input-form")),
      source: "",
      files: ["registry/default/example/input-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-with-button": {
      name: "input-with-button",
      type: "components:example",
      registryDependencies: ["input","button"],
      component: React.lazy(() => import("~/registry/default/example/input-with-button")),
      source: "",
      files: ["registry/default/example/input-with-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-with-label": {
      name: "input-with-label",
      type: "components:example",
      registryDependencies: ["input","button","label"],
      component: React.lazy(() => import("~/registry/default/example/input-with-label")),
      source: "",
      files: ["registry/default/example/input-with-label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-with-text": {
      name: "input-with-text",
      type: "components:example",
      registryDependencies: ["input","button","label"],
      component: React.lazy(() => import("~/registry/default/example/input-with-text")),
      source: "",
      files: ["registry/default/example/input-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-demo": {
      name: "input-otp-demo",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/default/example/input-otp-demo")),
      source: "",
      files: ["registry/default/example/input-otp-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-pattern": {
      name: "input-otp-pattern",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/default/example/input-otp-pattern")),
      source: "",
      files: ["registry/default/example/input-otp-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-separator": {
      name: "input-otp-separator",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/default/example/input-otp-separator")),
      source: "",
      files: ["registry/default/example/input-otp-separator.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-controlled": {
      name: "input-otp-controlled",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/default/example/input-otp-controlled")),
      source: "",
      files: ["registry/default/example/input-otp-controlled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-form": {
      name: "input-otp-form",
      type: "components:example",
      registryDependencies: ["input-otp","form"],
      component: React.lazy(() => import("~/registry/default/example/input-otp-form")),
      source: "",
      files: ["registry/default/example/input-otp-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-disabled": {
      name: "input-otp-disabled",
      type: "components:example",
      registryDependencies: ["input-otp","form"],
      component: React.lazy(() => import("~/registry/default/example/input-otp-disabled")),
      source: "",
      files: ["registry/default/example/input-otp-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-default": {
      name: "input-phone-default",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/default/example/input-phone-default")),
      source: "",
      files: ["registry/default/example/input-phone-default.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-demo": {
      name: "input-phone-demo",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/default/example/input-phone-demo")),
      source: "",
      files: ["registry/default/example/input-phone-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-form": {
      name: "input-phone-form",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/default/example/input-phone-form")),
      source: "",
      files: ["registry/default/example/input-phone-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-initial": {
      name: "input-phone-initial",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/default/example/input-phone-initial")),
      source: "",
      files: ["registry/default/example/input-phone-initial.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-international": {
      name: "input-phone-international",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/default/example/input-phone-international")),
      source: "",
      files: ["registry/default/example/input-phone-international.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-national": {
      name: "input-phone-national",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/default/example/input-phone-national")),
      source: "",
      files: ["registry/default/example/input-phone-national.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "label-demo": {
      name: "label-demo",
      type: "components:example",
      registryDependencies: ["label"],
      component: React.lazy(() => import("~/registry/default/example/label-demo")),
      source: "",
      files: ["registry/default/example/label-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "menubar-demo": {
      name: "menubar-demo",
      type: "components:example",
      registryDependencies: ["menubar"],
      component: React.lazy(() => import("~/registry/default/example/menubar-demo")),
      source: "",
      files: ["registry/default/example/menubar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "navigation-menu-demo": {
      name: "navigation-menu-demo",
      type: "components:example",
      registryDependencies: ["navigation-menu"],
      component: React.lazy(() => import("~/registry/default/example/navigation-menu-demo")),
      source: "",
      files: ["registry/default/example/navigation-menu-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pagination-demo": {
      name: "pagination-demo",
      type: "components:example",
      registryDependencies: ["pagination"],
      component: React.lazy(() => import("~/registry/default/example/pagination-demo")),
      source: "",
      files: ["registry/default/example/pagination-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "popover-demo": {
      name: "popover-demo",
      type: "components:example",
      registryDependencies: ["popover"],
      component: React.lazy(() => import("~/registry/default/example/popover-demo")),
      source: "",
      files: ["registry/default/example/popover-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "progress-demo": {
      name: "progress-demo",
      type: "components:example",
      registryDependencies: ["progress"],
      component: React.lazy(() => import("~/registry/default/example/progress-demo")),
      source: "",
      files: ["registry/default/example/progress-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radio-group-demo": {
      name: "radio-group-demo",
      type: "components:example",
      registryDependencies: ["radio-group"],
      component: React.lazy(() => import("~/registry/default/example/radio-group-demo")),
      source: "",
      files: ["registry/default/example/radio-group-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radio-group-form": {
      name: "radio-group-form",
      type: "components:example",
      registryDependencies: ["radio-group","form"],
      component: React.lazy(() => import("~/registry/default/example/radio-group-form")),
      source: "",
      files: ["registry/default/example/radio-group-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-demo": {
      name: "resizable-demo",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/default/example/resizable-demo")),
      source: "",
      files: ["registry/default/example/resizable-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-demo-with-handle": {
      name: "resizable-demo-with-handle",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/default/example/resizable-demo-with-handle")),
      source: "",
      files: ["registry/default/example/resizable-demo-with-handle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-vertical": {
      name: "resizable-vertical",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/default/example/resizable-vertical")),
      source: "",
      files: ["registry/default/example/resizable-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-handle": {
      name: "resizable-handle",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/default/example/resizable-handle")),
      source: "",
      files: ["registry/default/example/resizable-handle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-area-demo": {
      name: "scroll-area-demo",
      type: "components:example",
      registryDependencies: ["scroll-area"],
      component: React.lazy(() => import("~/registry/default/example/scroll-area-demo")),
      source: "",
      files: ["registry/default/example/scroll-area-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-area-horizontal-demo": {
      name: "scroll-area-horizontal-demo",
      type: "components:example",
      registryDependencies: ["scroll-area"],
      component: React.lazy(() => import("~/registry/default/example/scroll-area-horizontal-demo")),
      source: "",
      files: ["registry/default/example/scroll-area-horizontal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select-demo": {
      name: "select-demo",
      type: "components:example",
      registryDependencies: ["select"],
      component: React.lazy(() => import("~/registry/default/example/select-demo")),
      source: "",
      files: ["registry/default/example/select-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select-scrollable": {
      name: "select-scrollable",
      type: "components:example",
      registryDependencies: ["select"],
      component: React.lazy(() => import("~/registry/default/example/select-scrollable")),
      source: "",
      files: ["registry/default/example/select-scrollable.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select-form": {
      name: "select-form",
      type: "components:example",
      registryDependencies: ["select"],
      component: React.lazy(() => import("~/registry/default/example/select-form")),
      source: "",
      files: ["registry/default/example/select-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separator-demo": {
      name: "separator-demo",
      type: "components:example",
      registryDependencies: ["separator"],
      component: React.lazy(() => import("~/registry/default/example/separator-demo")),
      source: "",
      files: ["registry/default/example/separator-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sheet-demo": {
      name: "sheet-demo",
      type: "components:example",
      registryDependencies: ["sheet"],
      component: React.lazy(() => import("~/registry/default/example/sheet-demo")),
      source: "",
      files: ["registry/default/example/sheet-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sheet-side": {
      name: "sheet-side",
      type: "components:example",
      registryDependencies: ["sheet"],
      component: React.lazy(() => import("~/registry/default/example/sheet-side")),
      source: "",
      files: ["registry/default/example/sheet-side.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "skeleton-demo": {
      name: "skeleton-demo",
      type: "components:example",
      registryDependencies: ["skeleton"],
      component: React.lazy(() => import("~/registry/default/example/skeleton-demo")),
      source: "",
      files: ["registry/default/example/skeleton-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "skeleton-card": {
      name: "skeleton-card",
      type: "components:example",
      registryDependencies: ["skeleton"],
      component: React.lazy(() => import("~/registry/default/example/skeleton-card")),
      source: "",
      files: ["registry/default/example/skeleton-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-demo": {
      name: "slider-demo",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-demo")),
      source: "",
      files: ["registry/default/example/slider-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-disabled": {
      name: "slider-disabled",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-disabled")),
      source: "",
      files: ["registry/default/example/slider-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-inverted": {
      name: "slider-inverted",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-inverted")),
      source: "",
      files: ["registry/default/example/slider-inverted.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-vertical": {
      name: "slider-vertical",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-vertical")),
      source: "",
      files: ["registry/default/example/slider-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-range": {
      name: "slider-range",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-range")),
      source: "",
      files: ["registry/default/example/slider-range.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-tick-marks-half": {
      name: "slider-tick-marks-half",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-tick-marks-half")),
      source: "",
      files: ["registry/default/example/slider-tick-marks-half.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-tick-marks-full": {
      name: "slider-tick-marks-full",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-tick-marks-full")),
      source: "",
      files: ["registry/default/example/slider-tick-marks-full.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-format-label": {
      name: "slider-format-label",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-format-label")),
      source: "",
      files: ["registry/default/example/slider-format-label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-format-label-side": {
      name: "slider-format-label-side",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/default/example/slider-format-label-side")),
      source: "",
      files: ["registry/default/example/slider-format-label-side.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sonner-demo": {
      name: "sonner-demo",
      type: "components:example",
      registryDependencies: ["sonner"],
      component: React.lazy(() => import("~/registry/default/example/sonner-demo")),
      source: "",
      files: ["registry/default/example/sonner-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "switch-demo": {
      name: "switch-demo",
      type: "components:example",
      registryDependencies: ["switch"],
      component: React.lazy(() => import("~/registry/default/example/switch-demo")),
      source: "",
      files: ["registry/default/example/switch-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "switch-form": {
      name: "switch-form",
      type: "components:example",
      registryDependencies: ["switch","form"],
      component: React.lazy(() => import("~/registry/default/example/switch-form")),
      source: "",
      files: ["registry/default/example/switch-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "table-demo": {
      name: "table-demo",
      type: "components:example",
      registryDependencies: ["table"],
      component: React.lazy(() => import("~/registry/default/example/table-demo")),
      source: "",
      files: ["registry/default/example/table-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tabs-demo": {
      name: "tabs-demo",
      type: "components:example",
      registryDependencies: ["tabs"],
      component: React.lazy(() => import("~/registry/default/example/tabs-demo")),
      source: "",
      files: ["registry/default/example/tabs-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-demo": {
      name: "textarea-demo",
      type: "components:example",
      registryDependencies: ["textarea"],
      component: React.lazy(() => import("~/registry/default/example/textarea-demo")),
      source: "",
      files: ["registry/default/example/textarea-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-disabled": {
      name: "textarea-disabled",
      type: "components:example",
      registryDependencies: ["textarea"],
      component: React.lazy(() => import("~/registry/default/example/textarea-disabled")),
      source: "",
      files: ["registry/default/example/textarea-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-form": {
      name: "textarea-form",
      type: "components:example",
      registryDependencies: ["textarea","form"],
      component: React.lazy(() => import("~/registry/default/example/textarea-form")),
      source: "",
      files: ["registry/default/example/textarea-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-with-button": {
      name: "textarea-with-button",
      type: "components:example",
      registryDependencies: ["textarea","button"],
      component: React.lazy(() => import("~/registry/default/example/textarea-with-button")),
      source: "",
      files: ["registry/default/example/textarea-with-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-with-label": {
      name: "textarea-with-label",
      type: "components:example",
      registryDependencies: ["textarea","label"],
      component: React.lazy(() => import("~/registry/default/example/textarea-with-label")),
      source: "",
      files: ["registry/default/example/textarea-with-label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-with-text": {
      name: "textarea-with-text",
      type: "components:example",
      registryDependencies: ["textarea","label"],
      component: React.lazy(() => import("~/registry/default/example/textarea-with-text")),
      source: "",
      files: ["registry/default/example/textarea-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-demo": {
      name: "toast-demo",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/default/example/toast-demo")),
      source: "",
      files: ["registry/default/example/toast-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-destructive": {
      name: "toast-destructive",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/default/example/toast-destructive")),
      source: "",
      files: ["registry/default/example/toast-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-simple": {
      name: "toast-simple",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/default/example/toast-simple")),
      source: "",
      files: ["registry/default/example/toast-simple.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-with-action": {
      name: "toast-with-action",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/default/example/toast-with-action")),
      source: "",
      files: ["registry/default/example/toast-with-action.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-with-title": {
      name: "toast-with-title",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/default/example/toast-with-title")),
      source: "",
      files: ["registry/default/example/toast-with-title.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-demo": {
      name: "toggle-group-demo",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/default/example/toggle-group-demo")),
      source: "",
      files: ["registry/default/example/toggle-group-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-disabled": {
      name: "toggle-group-disabled",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/default/example/toggle-group-disabled")),
      source: "",
      files: ["registry/default/example/toggle-group-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-lg": {
      name: "toggle-group-lg",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/default/example/toggle-group-lg")),
      source: "",
      files: ["registry/default/example/toggle-group-lg.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-outline": {
      name: "toggle-group-outline",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/default/example/toggle-group-outline")),
      source: "",
      files: ["registry/default/example/toggle-group-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-sm": {
      name: "toggle-group-sm",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/default/example/toggle-group-sm")),
      source: "",
      files: ["registry/default/example/toggle-group-sm.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-single": {
      name: "toggle-group-single",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/default/example/toggle-group-single")),
      source: "",
      files: ["registry/default/example/toggle-group-single.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-demo": {
      name: "toggle-demo",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/default/example/toggle-demo")),
      source: "",
      files: ["registry/default/example/toggle-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-disabled": {
      name: "toggle-disabled",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/default/example/toggle-disabled")),
      source: "",
      files: ["registry/default/example/toggle-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-lg": {
      name: "toggle-lg",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/default/example/toggle-lg")),
      source: "",
      files: ["registry/default/example/toggle-lg.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-outline": {
      name: "toggle-outline",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/default/example/toggle-outline")),
      source: "",
      files: ["registry/default/example/toggle-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-sm": {
      name: "toggle-sm",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/default/example/toggle-sm")),
      source: "",
      files: ["registry/default/example/toggle-sm.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-with-text": {
      name: "toggle-with-text",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/default/example/toggle-with-text")),
      source: "",
      files: ["registry/default/example/toggle-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "mode-toggle-group": {
      name: "mode-toggle-group",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/mode-toggle-group")),
      source: "",
      files: ["registry/default/example/mode-toggle-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-demo": {
      name: "tooltip-demo",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/default/example/tooltip-demo")),
      source: "",
      files: ["registry/default/example/tooltip-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-outline": {
      name: "tooltip-outline",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/default/example/tooltip-outline")),
      source: "",
      files: ["registry/default/example/tooltip-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-secondary": {
      name: "tooltip-secondary",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/default/example/tooltip-secondary")),
      source: "",
      files: ["registry/default/example/tooltip-secondary.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-with-toggle-demo": {
      name: "tooltip-with-toggle-demo",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/default/example/tooltip-with-toggle-demo")),
      source: "",
      files: ["registry/default/example/tooltip-with-toggle-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-blockquote": {
      name: "typography-blockquote",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-blockquote")),
      source: "",
      files: ["registry/default/example/typography-blockquote.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-demo": {
      name: "typography-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-demo")),
      source: "",
      files: ["registry/default/example/typography-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h1": {
      name: "typography-h1",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-h1")),
      source: "",
      files: ["registry/default/example/typography-h1.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h2": {
      name: "typography-h2",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-h2")),
      source: "",
      files: ["registry/default/example/typography-h2.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h3": {
      name: "typography-h3",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-h3")),
      source: "",
      files: ["registry/default/example/typography-h3.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h4": {
      name: "typography-h4",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-h4")),
      source: "",
      files: ["registry/default/example/typography-h4.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-inline-code": {
      name: "typography-inline-code",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-inline-code")),
      source: "",
      files: ["registry/default/example/typography-inline-code.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-large": {
      name: "typography-large",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-large")),
      source: "",
      files: ["registry/default/example/typography-large.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-lead": {
      name: "typography-lead",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-lead")),
      source: "",
      files: ["registry/default/example/typography-lead.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-list": {
      name: "typography-list",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-list")),
      source: "",
      files: ["registry/default/example/typography-list.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-muted": {
      name: "typography-muted",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-muted")),
      source: "",
      files: ["registry/default/example/typography-muted.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-p": {
      name: "typography-p",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-p")),
      source: "",
      files: ["registry/default/example/typography-p.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-small": {
      name: "typography-small",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-small")),
      source: "",
      files: ["registry/default/example/typography-small.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-table": {
      name: "typography-table",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typography-table")),
      source: "",
      files: ["registry/default/example/typography-table.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "mode-toggle": {
      name: "mode-toggle",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/mode-toggle")),
      source: "",
      files: ["registry/default/example/mode-toggle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cards": {
      name: "cards",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/cards")),
      source: "",
      files: ["registry/default/example/cards/cards.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo": {
      name: "chart-bar-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/chart-bar-demo")),
      source: "",
      files: ["registry/default/example/chart-bar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-grid": {
      name: "chart-bar-demo-grid",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/chart-bar-demo-grid")),
      source: "",
      files: ["registry/default/example/chart-bar-demo-grid.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-axis": {
      name: "chart-bar-demo-axis",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/chart-bar-demo-axis")),
      source: "",
      files: ["registry/default/example/chart-bar-demo-axis.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-tooltip": {
      name: "chart-bar-demo-tooltip",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/chart-bar-demo-tooltip")),
      source: "",
      files: ["registry/default/example/chart-bar-demo-tooltip.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-legend": {
      name: "chart-bar-demo-legend",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/chart-bar-demo-legend")),
      source: "",
      files: ["registry/default/example/chart-bar-demo-legend.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-tooltip-demo": {
      name: "chart-tooltip-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/chart-tooltip-demo")),
      source: "",
      files: ["registry/default/example/chart-tooltip-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "magic-card-demo": {
      name: "magic-card-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/magic-card-demo")),
      source: "",
      files: ["registry/default/example/magic-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "neon-gradient-card-demo": {
      name: "neon-gradient-card-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/neon-gradient-card-demo")),
      source: "",
      files: ["registry/default/example/neon-gradient-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "magic-card-gradient": {
      name: "magic-card-gradient",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/magic-card-gradient")),
      source: "",
      files: ["registry/default/example/magic-card-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "linear-gradient-demo": {
      name: "linear-gradient-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/linear-gradient-demo")),
      source: "",
      files: ["registry/default/example/linear-gradient-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radial-gradient-demo": {
      name: "radial-gradient-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/radial-gradient-demo")),
      source: "",
      files: ["registry/default/example/radial-gradient-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "meteors-demo": {
      name: "meteors-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/meteors-demo")),
      source: "",
      files: ["registry/default/example/meteors-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern-demo": {
      name: "grid-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/grid-pattern-demo")),
      source: "",
      files: ["registry/default/example/grid-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern-linear-gradient": {
      name: "grid-pattern-linear-gradient",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/grid-pattern-linear-gradient")),
      source: "",
      files: ["registry/default/example/grid-pattern-linear-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern-dashed": {
      name: "grid-pattern-dashed",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/grid-pattern-dashed")),
      source: "",
      files: ["registry/default/example/grid-pattern-dashed.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dot-pattern-demo": {
      name: "dot-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/dot-pattern-demo")),
      source: "",
      files: ["registry/default/example/dot-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dot-pattern-linear-gradient": {
      name: "dot-pattern-linear-gradient",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/dot-pattern-linear-gradient")),
      source: "",
      files: ["registry/default/example/dot-pattern-linear-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-demo": {
      name: "marquee-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/marquee-demo")),
      source: "",
      files: ["registry/default/example/marquee-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-demo-vertical": {
      name: "marquee-demo-vertical",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/marquee-demo-vertical")),
      source: "",
      files: ["registry/default/example/marquee-demo-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-logos": {
      name: "marquee-logos",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/marquee-logos")),
      source: "",
      files: ["registry/default/example/marquee-logos.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-3d": {
      name: "marquee-3d",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/marquee-3d")),
      source: "",
      files: ["registry/default/example/marquee-3d.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "globe-demo": {
      name: "globe-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/globe-demo")),
      source: "",
      files: ["registry/default/example/globe-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card-demo": {
      name: "tweet-card-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/tweet-card-demo")),
      source: "",
      files: ["registry/default/example/tweet-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card-images": {
      name: "tweet-card-images",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/tweet-card-images")),
      source: "",
      files: ["registry/default/example/tweet-card-images.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card-meta-preview": {
      name: "tweet-card-meta-preview",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/tweet-card-meta-preview")),
      source: "",
      files: ["registry/default/example/tweet-card-meta-preview.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shimmer-button-demo": {
      name: "shimmer-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/shimmer-button-demo")),
      source: "",
      files: ["registry/default/example/shimmer-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hero-video-demo": {
      name: "hero-video-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/hero-video-demo")),
      source: "",
      files: ["registry/default/example/hero-video-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hero-video-youtube": {
      name: "hero-video-youtube",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/hero-video-youtube")),
      source: "",
      files: ["registry/default/example/hero-video-youtube.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "bento-demo": {
      name: "bento-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/bento-demo")),
      source: "",
      files: ["registry/default/example/bento-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "bento-demo-vertical": {
      name: "bento-demo-vertical",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/bento-demo-vertical")),
      source: "",
      files: ["registry/default/example/bento-demo-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "number-ticker-demo": {
      name: "number-ticker-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/number-ticker-demo")),
      source: "",
      files: ["registry/default/example/number-ticker-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "ripple-demo": {
      name: "ripple-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/ripple-demo")),
      source: "",
      files: ["registry/default/example/ripple-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "retro-grid-demo": {
      name: "retro-grid-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/retro-grid-demo")),
      source: "",
      files: ["registry/default/example/retro-grid-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-list-demo": {
      name: "animated-list-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-list-demo")),
      source: "",
      files: ["registry/default/example/animated-list-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-lines-demo": {
      name: "animated-lines-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-lines-demo")),
      source: "",
      files: ["registry/default/example/animated-lines-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-shiny-text-demo": {
      name: "animated-shiny-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-shiny-text-demo")),
      source: "",
      files: ["registry/default/example/animated-shiny-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "particles-demo": {
      name: "particles-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/particles-demo")),
      source: "",
      files: ["registry/default/example/particles-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-grid-pattern-demo": {
      name: "animated-grid-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-grid-pattern-demo")),
      source: "",
      files: ["registry/default/example/animated-grid-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "border-beam-demo": {
      name: "border-beam-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/border-beam-demo")),
      source: "",
      files: ["registry/default/example/border-beam-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-demo": {
      name: "animated-beam-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-beam-demo")),
      source: "",
      files: ["registry/default/example/animated-beam-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-unidirectional": {
      name: "animated-beam-unidirectional",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-beam-unidirectional")),
      source: "",
      files: ["registry/default/example/animated-beam-unidirectional.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-bidirectional": {
      name: "animated-beam-bidirectional",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-beam-bidirectional")),
      source: "",
      files: ["registry/default/example/animated-beam-bidirectional.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-multiple-inputs": {
      name: "animated-beam-multiple-inputs",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-beam-multiple-inputs")),
      source: "",
      files: ["registry/default/example/animated-beam-multiple-inputs.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-multiple-outputs": {
      name: "animated-beam-multiple-outputs",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-beam-multiple-outputs")),
      source: "",
      files: ["registry/default/example/animated-beam-multiple-outputs.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-1": {
      name: "animated-feature-card-1",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-1")),
      source: "",
      files: ["registry/default/example/animated-feature-card-1.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-2": {
      name: "animated-feature-card-2",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-2")),
      source: "",
      files: ["registry/default/example/animated-feature-card-2.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-3": {
      name: "animated-feature-card-3",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-3")),
      source: "",
      files: ["registry/default/example/animated-feature-card-3.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-4": {
      name: "animated-feature-card-4",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-4")),
      source: "",
      files: ["registry/default/example/animated-feature-card-4.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-5": {
      name: "animated-feature-card-5",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-5")),
      source: "",
      files: ["registry/default/example/animated-feature-card-5.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-6": {
      name: "animated-feature-card-6",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-6")),
      source: "",
      files: ["registry/default/example/animated-feature-card-6.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-7": {
      name: "animated-feature-card-7",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-7")),
      source: "",
      files: ["registry/default/example/animated-feature-card-7.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-8": {
      name: "animated-feature-card-8",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-8")),
      source: "",
      files: ["registry/default/example/animated-feature-card-8.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-9": {
      name: "animated-feature-card-9",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-9")),
      source: "",
      files: ["registry/default/example/animated-feature-card-9.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-10": {
      name: "animated-feature-card-10",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-feature-card-10")),
      source: "",
      files: ["registry/default/example/animated-feature-card-10.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "text-reveal-demo": {
      name: "text-reveal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/text-reveal-demo")),
      source: "",
      files: ["registry/default/example/text-reveal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-gradient-text-demo": {
      name: "animated-gradient-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-gradient-text-demo")),
      source: "",
      files: ["registry/default/example/animated-gradient-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "orbiting-circles-demo": {
      name: "orbiting-circles-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/orbiting-circles-demo")),
      source: "",
      files: ["registry/default/example/orbiting-circles-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dock-demo": {
      name: "dock-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/dock-demo")),
      source: "",
      files: ["registry/default/example/dock-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-rotate-demo": {
      name: "word-rotate-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/word-rotate-demo")),
      source: "",
      files: ["registry/default/example/word-rotate-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar-circles-demo": {
      name: "avatar-circles-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/avatar-circles-demo")),
      source: "",
      files: ["registry/default/example/avatar-circles-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-pull-up-demo": {
      name: "word-pull-up-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/word-pull-up-demo")),
      source: "",
      files: ["registry/default/example/word-pull-up-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typing-animation-demo": {
      name: "typing-animation-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/typing-animation-demo")),
      source: "",
      files: ["registry/default/example/typing-animation-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-text-demo": {
      name: "wavy-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/wavy-text-demo")),
      source: "",
      files: ["registry/default/example/wavy-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-in-demo": {
      name: "blur-in-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/blur-in-demo")),
      source: "",
      files: ["registry/default/example/blur-in-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separate-away-demo": {
      name: "separate-away-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/separate-away-demo")),
      source: "",
      files: ["registry/default/example/separate-away-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-based-velocity-demo": {
      name: "scroll-based-velocity-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/scroll-based-velocity-demo")),
      source: "",
      files: ["registry/default/example/scroll-based-velocity-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "letter-pullup-demo": {
      name: "letter-pullup-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/letter-pullup-demo")),
      source: "",
      files: ["registry/default/example/letter-pullup-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "flip-text-demo": {
      name: "flip-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/flip-text-demo")),
      source: "",
      files: ["registry/default/example/flip-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sparkles-text-demo": {
      name: "sparkles-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/sparkles-text-demo")),
      source: "",
      files: ["registry/default/example/sparkles-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "icon-cloud-demo": {
      name: "icon-cloud-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/icon-cloud-demo")),
      source: "",
      files: ["registry/default/example/icon-cloud-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-dot-pattern-demo": {
      name: "wavy-dot-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/wavy-dot-pattern-demo")),
      source: "",
      files: ["registry/default/example/wavy-dot-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gradual-spacing-demo": {
      name: "gradual-spacing-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/gradual-spacing-demo")),
      source: "",
      files: ["registry/default/example/gradual-spacing-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-fade-in-demo": {
      name: "word-fade-in-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/word-fade-in-demo")),
      source: "",
      files: ["registry/default/example/word-fade-in-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "fade-text-demo": {
      name: "fade-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/fade-text-demo")),
      source: "",
      files: ["registry/default/example/fade-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shiny-button-demo": {
      name: "shiny-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/shiny-button-demo")),
      source: "",
      files: ["registry/default/example/shiny-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "box-reveal-demo": {
      name: "box-reveal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/box-reveal-demo")),
      source: "",
      files: ["registry/default/example/box-reveal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gauge-circle-demo": {
      name: "gauge-circle-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/gauge-circle-demo")),
      source: "",
      files: ["registry/default/example/gauge-circle-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shine-border-demo": {
      name: "shine-border-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/shine-border-demo")),
      source: "",
      files: ["registry/default/example/shine-border-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shine-border-demo-2": {
      name: "shine-border-demo-2",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/shine-border-demo-2")),
      source: "",
      files: ["registry/default/example/shine-border-demo-2.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-hover": {
      name: "confetti-hover",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-hover")),
      source: "",
      files: ["registry/default/example/confetti-hover.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-basic-cannon": {
      name: "confetti-basic-cannon",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-basic-cannon")),
      source: "",
      files: ["registry/default/example/confetti-basic-cannon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-random-direction": {
      name: "confetti-random-direction",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-random-direction")),
      source: "",
      files: ["registry/default/example/confetti-random-direction.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-fireworks": {
      name: "confetti-fireworks",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-fireworks")),
      source: "",
      files: ["registry/default/example/confetti-fireworks.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-stars": {
      name: "confetti-stars",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-stars")),
      source: "",
      files: ["registry/default/example/confetti-stars.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-side-cannons": {
      name: "confetti-side-cannons",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-side-cannons")),
      source: "",
      files: ["registry/default/example/confetti-side-cannons.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-custom-shapes": {
      name: "confetti-custom-shapes",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-custom-shapes")),
      source: "",
      files: ["registry/default/example/confetti-custom-shapes.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-emoji": {
      name: "confetti-emoji",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-emoji")),
      source: "",
      files: ["registry/default/example/confetti-emoji.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-subscribe-button-demo": {
      name: "animated-subscribe-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/animated-subscribe-button-demo")),
      source: "",
      files: ["registry/default/example/animated-subscribe-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cool-mode-demo": {
      name: "cool-mode-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/cool-mode-demo")),
      source: "",
      files: ["registry/default/example/cool-mode-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cool-mode-custom": {
      name: "cool-mode-custom",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/cool-mode-custom")),
      source: "",
      files: ["registry/default/example/cool-mode-custom.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-demo": {
      name: "timeline-demo",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/default/example/timeline-demo")),
      source: "",
      files: ["registry/default/example/timeline-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-right": {
      name: "timeline-right",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/default/example/timeline-right")),
      source: "",
      files: ["registry/default/example/timeline-right.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-alternate": {
      name: "timeline-alternate",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/default/example/timeline-alternate")),
      source: "",
      files: ["registry/default/example/timeline-alternate.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-labels": {
      name: "timeline-labels",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/default/example/timeline-labels")),
      source: "",
      files: ["registry/default/example/timeline-labels.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating-demo": {
      name: "star-rating-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/star-rating-demo")),
      source: "",
      files: ["registry/default/example/star-rating-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating-colored": {
      name: "star-rating-colored",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/star-rating-colored")),
      source: "",
      files: ["registry/default/example/star-rating-colored.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating-icon": {
      name: "star-rating-icon",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/star-rating-icon")),
      source: "",
      files: ["registry/default/example/star-rating-icon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-demo": {
      name: "stepper-demo",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-demo")),
      source: "",
      files: ["registry/default/example/stepper-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-custom-styles": {
      name: "stepper-custom-styles",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-custom-styles")),
      source: "",
      files: ["registry/default/example/stepper-custom-styles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-orientation": {
      name: "stepper-orientation",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-orientation")),
      source: "",
      files: ["registry/default/example/stepper-orientation.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-description": {
      name: "stepper-description",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-description")),
      source: "",
      files: ["registry/default/example/stepper-description.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-sizes": {
      name: "stepper-sizes",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-sizes")),
      source: "",
      files: ["registry/default/example/stepper-sizes.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-form": {
      name: "stepper-form",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-form")),
      source: "",
      files: ["registry/default/example/stepper-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-variants": {
      name: "stepper-variants",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-variants")),
      source: "",
      files: ["registry/default/example/stepper-variants.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-custom-icons": {
      name: "stepper-custom-icons",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-custom-icons")),
      source: "",
      files: ["registry/default/example/stepper-custom-icons.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-footer-inside": {
      name: "stepper-footer-inside",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-footer-inside")),
      source: "",
      files: ["registry/default/example/stepper-footer-inside.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-scroll-tracking": {
      name: "stepper-scroll-tracking",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-scroll-tracking")),
      source: "",
      files: ["registry/default/example/stepper-scroll-tracking.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-clickable-steps": {
      name: "stepper-clickable-steps",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-clickable-steps")),
      source: "",
      files: ["registry/default/example/stepper-clickable-steps.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-optional-steps": {
      name: "stepper-optional-steps",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-optional-steps")),
      source: "",
      files: ["registry/default/example/stepper-optional-steps.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-state": {
      name: "stepper-state",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/default/example/stepper-state")),
      source: "",
      files: ["registry/default/example/stepper-state.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tour-demo": {
      name: "tour-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/tour-demo")),
      source: "",
      files: ["registry/default/example/tour-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc-demo": {
      name: "toc-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/toc-demo")),
      source: "",
      files: ["registry/default/example/toc-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc-nested": {
      name: "toc-nested",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/toc-nested")),
      source: "",
      files: ["registry/default/example/toc-nested.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc-active": {
      name: "toc-active",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/toc-active")),
      source: "",
      files: ["registry/default/example/toc-active.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-basic": {
      name: "multi-select-basic",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/default/example/multi-select-basic")),
      source: "",
      files: ["registry/default/example/multi-select-basic.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-filter": {
      name: "multi-select-filter",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/default/example/multi-select-filter")),
      source: "",
      files: ["registry/default/example/multi-select-filter.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-controllable-search": {
      name: "multi-select-controllable-search",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/default/example/multi-select-controllable-search")),
      source: "",
      files: ["registry/default/example/multi-select-controllable-search.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-input-custom": {
      name: "multi-select-input-custom",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/default/example/multi-select-input-custom")),
      source: "",
      files: ["registry/default/example/multi-select-input-custom.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-limit-count": {
      name: "multi-select-limit-count",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/default/example/multi-select-limit-count")),
      source: "",
      files: ["registry/default/example/multi-select-limit-count.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-limit-display-length": {
      name: "multi-select-limit-display-length",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/default/example/multi-select-limit-display-length")),
      source: "",
      files: ["registry/default/example/multi-select-limit-display-length.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-form": {
      name: "multi-select-form",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/default/example/multi-select-form")),
      source: "",
      files: ["registry/default/example/multi-select-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-progress-demo": {
      name: "scroll-progress-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/scroll-progress-demo")),
      source: "",
      files: ["registry/default/example/scroll-progress-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "video-modal-demo": {
      name: "video-modal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/video-modal-demo")),
      source: "",
      files: ["registry/default/example/video-modal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scratch-to-reveal-demo": {
      name: "scratch-to-reveal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/scratch-to-reveal-demo")),
      source: "",
      files: ["registry/default/example/scratch-to-reveal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-demo": {
      name: "confetti-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/confetti-demo")),
      source: "",
      files: ["registry/default/example/confetti-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-fade-demo": {
      name: "blur-fade-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/blur-fade-demo")),
      source: "",
      files: ["registry/default/example/blur-fade-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-fade-text-demo": {
      name: "blur-fade-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/blur-fade-text-demo")),
      source: "",
      files: ["registry/default/example/blur-fade-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "file-tree-demo": {
      name: "file-tree-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/default/example/file-tree-demo")),
      source: "",
      files: ["registry/default/example/file-tree-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dashboard-05": {
      name: "dashboard-05",
      type: "components:block",
      registryDependencies: ["badge","breadcrumb","button","card","dropdown-menu","input","pagination","progress","separator","sheet","table","tabs","tooltip"],
      component: React.lazy(() => import("~/registry/default/block/dashboard-05")),
      source: "__registry__/default/block/dashboard-05.tsx",
      files: ["registry/default/block/dashboard-05.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-05-chunk-0",
        description: "A card for an orders dashboard with a description and a button to create a new order.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-05-chunk-0")),
        file: "registry/default/block/dashboard-05-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-1",
        description: "A stats card showing this week's total sales in USD, the percentage difference from last week, and a progress bar.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-05-chunk-1")),
        file: "registry/default/block/dashboard-05-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-2",
        description: "A stats card showing this month's total sales in USD, the percentage difference from last month, and a progress bar.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-05-chunk-2")),
        file: "registry/default/block/dashboard-05-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-3",
        description: "A table of recent orders showing the following columns: Customer, Type, Status, Date, and Amount.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-05-chunk-3")),
        file: "registry/default/block/dashboard-05-chunk-3.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-4",
        description: "An order details card with order details, shipping information, customer information and payment information.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-05-chunk-4")),
        file: "registry/default/block/dashboard-05-chunk-4.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-06": {
      name: "dashboard-06",
      type: "components:block",
      registryDependencies: ["badge","breadcrumb","button","card","dropdown-menu","input","sheet","table","tabs","tooltip"],
      component: React.lazy(() => import("~/registry/default/block/dashboard-06")),
      source: "__registry__/default/block/dashboard-06.tsx",
      files: ["registry/default/block/dashboard-06.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-06-chunk-0",
        description: "A list of products in a table with actions. Each row has an image, name, status, price, total sales, created at and actions.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-06-chunk-0")),
        file: "registry/default/block/dashboard-06-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-07": {
      name: "dashboard-07",
      type: "components:block",
      registryDependencies: ["badge","breadcrumb","button","card","dropdown-menu","input","pagination","progress","separator","sheet","table","tabs","tooltip"],
      component: React.lazy(() => import("~/registry/default/block/dashboard-07")),
      source: "__registry__/default/block/dashboard-07.tsx",
      files: ["registry/default/block/dashboard-07.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-07-chunk-0",
        description: "A card with a form to edit the product details",
        component: React.lazy(() => import("~/registry/default/block/dashboard-07-chunk-0")),
        file: "registry/default/block/dashboard-07-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-1",
        description: "A card with a form to edit the product stock and variants",
        component: React.lazy(() => import("~/registry/default/block/dashboard-07-chunk-1")),
        file: "registry/default/block/dashboard-07-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-2",
        description: "A card with a form to edit the product category and subcategory",
        component: React.lazy(() => import("~/registry/default/block/dashboard-07-chunk-2")),
        file: "registry/default/block/dashboard-07-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-3",
        description: "A card with a form to edit the product status",
        component: React.lazy(() => import("~/registry/default/block/dashboard-07-chunk-3")),
        file: "registry/default/block/dashboard-07-chunk-3.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-4",
        description: "A card with a form to upload product images",
        component: React.lazy(() => import("~/registry/default/block/dashboard-07-chunk-4")),
        file: "registry/default/block/dashboard-07-chunk-4.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-5",
        description: "A card with a call to action to archive the product",
        component: React.lazy(() => import("~/registry/default/block/dashboard-07-chunk-5")),
        file: "registry/default/block/dashboard-07-chunk-5.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-04": {
      name: "dashboard-04",
      type: "components:block",
      registryDependencies: ["button","card","dropdown-menu","input"],
      component: React.lazy(() => import("~/registry/default/block/dashboard-04")),
      source: "__registry__/default/block/dashboard-04.tsx",
      files: ["registry/default/block/dashboard-04.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-04-chunk-0",
        description: "A sidebar navigation with links to general, security, integrations, support, organizations, and advanced settings.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-04-chunk-0")),
        file: "registry/default/block/dashboard-04-chunk-0.tsx",
        container: {
          className: "chunk-container after:right-0"
        }
      },{
        name: "dashboard-04-chunk-1",
        description: "A form to update the store name.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-04-chunk-1")),
        file: "registry/default/block/dashboard-04-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-04-chunk-2",
        description: "A form to update the plugins directory with a checkbox to allow administrators to change the directory.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-04-chunk-2")),
        file: "registry/default/block/dashboard-04-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-03": {
      name: "dashboard-03",
      type: "components:block",
      registryDependencies: ["badge","button","drawer","input","label","select","textarea","tooltip"],
      component: React.lazy(() => import("~/registry/default/block/dashboard-03")),
      source: "__registry__/default/block/dashboard-03.tsx",
      files: ["registry/default/block/dashboard-03.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-03-chunk-0",
        description: "A settings form a configuring an AI model and messages.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-03-chunk-0")),
        file: "registry/default/block/dashboard-03-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-03-chunk-1",
        description: "A form for sending a message to an AI chatbot. The form has a textarea and buttons to upload files and record audio.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-03-chunk-1")),
        file: "registry/default/block/dashboard-03-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-02": {
      name: "dashboard-02",
      type: "components:block",
      registryDependencies: ["badge","button","card","dropdown-menu","input"],
      component: React.lazy(() => import("~/registry/default/block/dashboard-02")),
      source: "__registry__/default/block/dashboard-02.tsx",
      files: ["registry/default/block/dashboard-02.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-02-chunk-0",
        description: "A card with a call to action",
        component: React.lazy(() => import("~/registry/default/block/dashboard-02-chunk-0")),
        file: "registry/default/block/dashboard-02-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-02-chunk-1",
        description: "An empty state showing no products with a heading, description and a call to action to add a product.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-02-chunk-1")),
        file: "registry/default/block/dashboard-02-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-01": {
      name: "dashboard-01",
      type: "components:block",
      registryDependencies: ["button","dropdown-menu","input","sheet"],
      component: React.lazy(() => import("~/registry/default/block/dashboard-01")),
      source: "__registry__/default/block/dashboard-01.tsx",
      files: ["registry/default/block/dashboard-01.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-01-chunk-0",
        description: "A card showing the total revenue in USD and the percentage difference from last month.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-01-chunk-0")),
        file: "registry/default/block/dashboard-01-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-1",
        description: "A card showing the total subscriptions and the percentage difference from last month.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-01-chunk-1")),
        file: "registry/default/block/dashboard-01-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-2",
        description: "A card showing the total sales and the percentage difference from last month.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-01-chunk-2")),
        file: "registry/default/block/dashboard-01-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-3",
        description: "A card showing the total active users and the percentage difference from last hour.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-01-chunk-3")),
        file: "registry/default/block/dashboard-01-chunk-3.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-4",
        description: "A card showing a table of recent transactions with a link to view all transactions.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-01-chunk-4")),
        file: "registry/default/block/dashboard-01-chunk-4.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-5",
        description: "A card showing a list of recent sales with customer names and email addresses.",
        component: React.lazy(() => import("~/registry/default/block/dashboard-01-chunk-5")),
        file: "registry/default/block/dashboard-01-chunk-5.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "authentication-01": {
      name: "authentication-01",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/default/block/authentication-01")),
      source: "__registry__/default/block/authentication-01.tsx",
      files: ["registry/default/block/authentication-01.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "authentication-02": {
      name: "authentication-02",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/default/block/authentication-02")),
      source: "__registry__/default/block/authentication-02.tsx",
      files: ["registry/default/block/authentication-02.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "authentication-03": {
      name: "authentication-03",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/default/block/authentication-03")),
      source: "__registry__/default/block/authentication-03.tsx",
      files: ["registry/default/block/authentication-03.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "authentication-04": {
      name: "authentication-04",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/default/block/authentication-04")),
      source: "__registry__/default/block/authentication-04.tsx",
      files: ["registry/default/block/authentication-04.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "chart-area-axes": {
      name: "chart-area-axes",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-axes")),
      source: "__registry__/default/block/chart-area-axes.tsx",
      files: ["registry/default/block/chart-area-axes.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-default": {
      name: "chart-area-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-default")),
      source: "__registry__/default/block/chart-area-default.tsx",
      files: ["registry/default/block/chart-area-default.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-gradient": {
      name: "chart-area-gradient",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-gradient")),
      source: "__registry__/default/block/chart-area-gradient.tsx",
      files: ["registry/default/block/chart-area-gradient.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-icons": {
      name: "chart-area-icons",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-icons")),
      source: "__registry__/default/block/chart-area-icons.tsx",
      files: ["registry/default/block/chart-area-icons.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-interactive": {
      name: "chart-area-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-interactive")),
      source: "__registry__/default/block/chart-area-interactive.tsx",
      files: ["registry/default/block/chart-area-interactive.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-legend": {
      name: "chart-area-legend",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-legend")),
      source: "__registry__/default/block/chart-area-legend.tsx",
      files: ["registry/default/block/chart-area-legend.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-linear": {
      name: "chart-area-linear",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-linear")),
      source: "__registry__/default/block/chart-area-linear.tsx",
      files: ["registry/default/block/chart-area-linear.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-stacked-expand": {
      name: "chart-area-stacked-expand",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-stacked-expand")),
      source: "__registry__/default/block/chart-area-stacked-expand.tsx",
      files: ["registry/default/block/chart-area-stacked-expand.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-stacked": {
      name: "chart-area-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-stacked")),
      source: "__registry__/default/block/chart-area-stacked.tsx",
      files: ["registry/default/block/chart-area-stacked.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-step": {
      name: "chart-area-step",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-area-step")),
      source: "__registry__/default/block/chart-area-step.tsx",
      files: ["registry/default/block/chart-area-step.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-bar-active": {
      name: "chart-bar-active",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-active")),
      source: "__registry__/default/block/chart-bar-active.tsx",
      files: ["registry/default/block/chart-bar-active.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-default": {
      name: "chart-bar-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-default")),
      source: "__registry__/default/block/chart-bar-default.tsx",
      files: ["registry/default/block/chart-bar-default.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-horizontal": {
      name: "chart-bar-horizontal",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-horizontal")),
      source: "__registry__/default/block/chart-bar-horizontal.tsx",
      files: ["registry/default/block/chart-bar-horizontal.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-interactive": {
      name: "chart-bar-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-interactive")),
      source: "__registry__/default/block/chart-bar-interactive.tsx",
      files: ["registry/default/block/chart-bar-interactive.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-label-custom": {
      name: "chart-bar-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-label-custom")),
      source: "__registry__/default/block/chart-bar-label-custom.tsx",
      files: ["registry/default/block/chart-bar-label-custom.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-label": {
      name: "chart-bar-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-label")),
      source: "__registry__/default/block/chart-bar-label.tsx",
      files: ["registry/default/block/chart-bar-label.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-mixed": {
      name: "chart-bar-mixed",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-mixed")),
      source: "__registry__/default/block/chart-bar-mixed.tsx",
      files: ["registry/default/block/chart-bar-mixed.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-multiple": {
      name: "chart-bar-multiple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-multiple")),
      source: "__registry__/default/block/chart-bar-multiple.tsx",
      files: ["registry/default/block/chart-bar-multiple.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-negative": {
      name: "chart-bar-negative",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-negative")),
      source: "__registry__/default/block/chart-bar-negative.tsx",
      files: ["registry/default/block/chart-bar-negative.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-stacked": {
      name: "chart-bar-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-bar-stacked")),
      source: "__registry__/default/block/chart-bar-stacked.tsx",
      files: ["registry/default/block/chart-bar-stacked.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-line-default": {
      name: "chart-line-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-default")),
      source: "__registry__/default/block/chart-line-default.tsx",
      files: ["registry/default/block/chart-line-default.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-dots-colors": {
      name: "chart-line-dots-colors",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-dots-colors")),
      source: "__registry__/default/block/chart-line-dots-colors.tsx",
      files: ["registry/default/block/chart-line-dots-colors.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-dots-custom": {
      name: "chart-line-dots-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-dots-custom")),
      source: "__registry__/default/block/chart-line-dots-custom.tsx",
      files: ["registry/default/block/chart-line-dots-custom.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-dots": {
      name: "chart-line-dots",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-dots")),
      source: "__registry__/default/block/chart-line-dots.tsx",
      files: ["registry/default/block/chart-line-dots.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-interactive": {
      name: "chart-line-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-interactive")),
      source: "__registry__/default/block/chart-line-interactive.tsx",
      files: ["registry/default/block/chart-line-interactive.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-label-custom": {
      name: "chart-line-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-label-custom")),
      source: "__registry__/default/block/chart-line-label-custom.tsx",
      files: ["registry/default/block/chart-line-label-custom.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-label": {
      name: "chart-line-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-label")),
      source: "__registry__/default/block/chart-line-label.tsx",
      files: ["registry/default/block/chart-line-label.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-linear": {
      name: "chart-line-linear",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-linear")),
      source: "__registry__/default/block/chart-line-linear.tsx",
      files: ["registry/default/block/chart-line-linear.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-multiple": {
      name: "chart-line-multiple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-multiple")),
      source: "__registry__/default/block/chart-line-multiple.tsx",
      files: ["registry/default/block/chart-line-multiple.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-step": {
      name: "chart-line-step",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-line-step")),
      source: "__registry__/default/block/chart-line-step.tsx",
      files: ["registry/default/block/chart-line-step.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-pie-donut-active": {
      name: "chart-pie-donut-active",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-donut-active")),
      source: "__registry__/default/block/chart-pie-donut-active.tsx",
      files: ["registry/default/block/chart-pie-donut-active.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-donut-text": {
      name: "chart-pie-donut-text",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-donut-text")),
      source: "__registry__/default/block/chart-pie-donut-text.tsx",
      files: ["registry/default/block/chart-pie-donut-text.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-donut": {
      name: "chart-pie-donut",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-donut")),
      source: "__registry__/default/block/chart-pie-donut.tsx",
      files: ["registry/default/block/chart-pie-donut.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-interactive": {
      name: "chart-pie-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-interactive")),
      source: "__registry__/default/block/chart-pie-interactive.tsx",
      files: ["registry/default/block/chart-pie-interactive.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-label-custom": {
      name: "chart-pie-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-label-custom")),
      source: "__registry__/default/block/chart-pie-label-custom.tsx",
      files: ["registry/default/block/chart-pie-label-custom.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-label-list": {
      name: "chart-pie-label-list",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-label-list")),
      source: "__registry__/default/block/chart-pie-label-list.tsx",
      files: ["registry/default/block/chart-pie-label-list.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-label": {
      name: "chart-pie-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-label")),
      source: "__registry__/default/block/chart-pie-label.tsx",
      files: ["registry/default/block/chart-pie-label.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-legend": {
      name: "chart-pie-legend",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-legend")),
      source: "__registry__/default/block/chart-pie-legend.tsx",
      files: ["registry/default/block/chart-pie-legend.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-separator-none": {
      name: "chart-pie-separator-none",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-separator-none")),
      source: "__registry__/default/block/chart-pie-separator-none.tsx",
      files: ["registry/default/block/chart-pie-separator-none.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-simple": {
      name: "chart-pie-simple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-simple")),
      source: "__registry__/default/block/chart-pie-simple.tsx",
      files: ["registry/default/block/chart-pie-simple.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-stacked": {
      name: "chart-pie-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-pie-stacked")),
      source: "__registry__/default/block/chart-pie-stacked.tsx",
      files: ["registry/default/block/chart-pie-stacked.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-radar-default": {
      name: "chart-radar-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-default")),
      source: "__registry__/default/block/chart-radar-default.tsx",
      files: ["registry/default/block/chart-radar-default.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-dots": {
      name: "chart-radar-dots",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-dots")),
      source: "__registry__/default/block/chart-radar-dots.tsx",
      files: ["registry/default/block/chart-radar-dots.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-circle-fill": {
      name: "chart-radar-grid-circle-fill",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-grid-circle-fill")),
      source: "__registry__/default/block/chart-radar-grid-circle-fill.tsx",
      files: ["registry/default/block/chart-radar-grid-circle-fill.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-circle-no-lines": {
      name: "chart-radar-grid-circle-no-lines",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-grid-circle-no-lines")),
      source: "__registry__/default/block/chart-radar-grid-circle-no-lines.tsx",
      files: ["registry/default/block/chart-radar-grid-circle-no-lines.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-circle": {
      name: "chart-radar-grid-circle",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-grid-circle")),
      source: "__registry__/default/block/chart-radar-grid-circle.tsx",
      files: ["registry/default/block/chart-radar-grid-circle.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-custom": {
      name: "chart-radar-grid-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-grid-custom")),
      source: "__registry__/default/block/chart-radar-grid-custom.tsx",
      files: ["registry/default/block/chart-radar-grid-custom.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-fill": {
      name: "chart-radar-grid-fill",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-grid-fill")),
      source: "__registry__/default/block/chart-radar-grid-fill.tsx",
      files: ["registry/default/block/chart-radar-grid-fill.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-none": {
      name: "chart-radar-grid-none",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-grid-none")),
      source: "__registry__/default/block/chart-radar-grid-none.tsx",
      files: ["registry/default/block/chart-radar-grid-none.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-icons": {
      name: "chart-radar-icons",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-icons")),
      source: "__registry__/default/block/chart-radar-icons.tsx",
      files: ["registry/default/block/chart-radar-icons.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-label-custom": {
      name: "chart-radar-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-label-custom")),
      source: "__registry__/default/block/chart-radar-label-custom.tsx",
      files: ["registry/default/block/chart-radar-label-custom.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-legend": {
      name: "chart-radar-legend",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-legend")),
      source: "__registry__/default/block/chart-radar-legend.tsx",
      files: ["registry/default/block/chart-radar-legend.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-lines-only": {
      name: "chart-radar-lines-only",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-lines-only")),
      source: "__registry__/default/block/chart-radar-lines-only.tsx",
      files: ["registry/default/block/chart-radar-lines-only.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-multiple": {
      name: "chart-radar-multiple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-multiple")),
      source: "__registry__/default/block/chart-radar-multiple.tsx",
      files: ["registry/default/block/chart-radar-multiple.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-radius": {
      name: "chart-radar-radius",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radar-radius")),
      source: "__registry__/default/block/chart-radar-radius.tsx",
      files: ["registry/default/block/chart-radar-radius.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radial-grid": {
      name: "chart-radial-grid",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radial-grid")),
      source: "__registry__/default/block/chart-radial-grid.tsx",
      files: ["registry/default/block/chart-radial-grid.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-label": {
      name: "chart-radial-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radial-label")),
      source: "__registry__/default/block/chart-radial-label.tsx",
      files: ["registry/default/block/chart-radial-label.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-shape": {
      name: "chart-radial-shape",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radial-shape")),
      source: "__registry__/default/block/chart-radial-shape.tsx",
      files: ["registry/default/block/chart-radial-shape.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-simple": {
      name: "chart-radial-simple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radial-simple")),
      source: "__registry__/default/block/chart-radial-simple.tsx",
      files: ["registry/default/block/chart-radial-simple.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-stacked": {
      name: "chart-radial-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radial-stacked")),
      source: "__registry__/default/block/chart-radial-stacked.tsx",
      files: ["registry/default/block/chart-radial-stacked.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-text": {
      name: "chart-radial-text",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/default/block/chart-radial-text")),
      source: "__registry__/default/block/chart-radial-text.tsx",
      files: ["registry/default/block/chart-radial-text.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
  },  "miami": {
    "accordion": {
      name: "accordion",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/accordion")),
      source: "",
      files: ["registry/miami/ui/accordion.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert": {
      name: "alert",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/alert")),
      source: "",
      files: ["registry/miami/ui/alert.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-dialog": {
      name: "alert-dialog",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/ui/alert-dialog")),
      source: "",
      files: ["registry/miami/ui/alert-dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam": {
      name: "animated-beam",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/animated-beam")),
      source: "",
      files: ["registry/miami/ui/animated-beam.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-grid-pattern": {
      name: "animated-grid-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/animated-grid-pattern")),
      source: "",
      files: ["registry/miami/ui/animated-grid-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-lines": {
      name: "animated-lines",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/animated-lines")),
      source: "",
      files: ["registry/miami/ui/animated-lines.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-list": {
      name: "animated-list",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/animated-list")),
      source: "",
      files: ["registry/miami/ui/animated-list.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-shiny-text": {
      name: "animated-shiny-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/animated-shiny-text")),
      source: "",
      files: ["registry/miami/ui/animated-shiny-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-subscribe-button": {
      name: "animated-subscribe-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/animated-subscribe-button")),
      source: "",
      files: ["registry/miami/ui/animated-subscribe-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-gradient-text": {
      name: "animated-gradient-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/animated-gradient-text")),
      source: "",
      files: ["registry/miami/ui/animated-gradient-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "aspect-ratio": {
      name: "aspect-ratio",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/aspect-ratio")),
      source: "",
      files: ["registry/miami/ui/aspect-ratio.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar": {
      name: "avatar",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/avatar")),
      source: "",
      files: ["registry/miami/ui/avatar.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar-circles": {
      name: "avatar-circles",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/avatar-circles")),
      source: "",
      files: ["registry/miami/ui/avatar-circles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge": {
      name: "badge",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/badge")),
      source: "",
      files: ["registry/miami/ui/badge.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "bento-grid": {
      name: "bento-grid",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/ui/bento-grid")),
      source: "",
      files: ["registry/miami/ui/bento-grid.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-in": {
      name: "blur-in",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/blur-in")),
      source: "",
      files: ["registry/miami/ui/blur-in.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "border-beam": {
      name: "border-beam",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/border-beam")),
      source: "",
      files: ["registry/miami/ui/border-beam.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "box-reveal": {
      name: "box-reveal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/box-reveal")),
      source: "",
      files: ["registry/miami/ui/box-reveal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb": {
      name: "breadcrumb",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/breadcrumb")),
      source: "",
      files: ["registry/miami/ui/breadcrumb.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button": {
      name: "button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/button")),
      source: "",
      files: ["registry/miami/ui/button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-fade": {
      name: "blur-fade",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/blur-fade")),
      source: "",
      files: ["registry/miami/ui/blur-fade.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "calendar": {
      name: "calendar",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/ui/calendar")),
      source: "",
      files: ["registry/miami/ui/calendar.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "card": {
      name: "card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/card")),
      source: "",
      files: ["registry/miami/ui/card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel": {
      name: "carousel",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/ui/carousel")),
      source: "",
      files: ["registry/miami/ui/carousel.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart": {
      name: "chart",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/chart")),
      source: "",
      files: ["registry/miami/ui/chart.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox": {
      name: "checkbox",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/checkbox")),
      source: "",
      files: ["registry/miami/ui/checkbox.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "client-tweet-card": {
      name: "client-tweet-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/client-tweet-card")),
      source: "",
      files: ["registry/miami/ui/client-tweet-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "collapsible": {
      name: "collapsible",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/collapsible")),
      source: "",
      files: ["registry/miami/ui/collapsible.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "command": {
      name: "command",
      type: "components:ui",
      registryDependencies: ["dialog"],
      component: React.lazy(() => import("~/registry/miami/ui/command")),
      source: "",
      files: ["registry/miami/ui/command.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti": {
      name: "confetti",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/confetti")),
      source: "",
      files: ["registry/miami/ui/confetti.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "context-menu": {
      name: "context-menu",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/context-menu")),
      source: "",
      files: ["registry/miami/ui/context-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cool-mode": {
      name: "cool-mode",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/cool-mode")),
      source: "",
      files: ["registry/miami/ui/cool-mode.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dialog": {
      name: "dialog",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/dialog")),
      source: "",
      files: ["registry/miami/ui/dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dock": {
      name: "dock",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/dock")),
      source: "",
      files: ["registry/miami/ui/dock.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dot-pattern": {
      name: "dot-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/dot-pattern")),
      source: "",
      files: ["registry/miami/ui/dot-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "drawer": {
      name: "drawer",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/drawer")),
      source: "",
      files: ["registry/miami/ui/drawer.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu": {
      name: "dropdown-menu",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/dropdown-menu")),
      source: "",
      files: ["registry/miami/ui/dropdown-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "fade-text": {
      name: "fade-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/fade-text")),
      source: "",
      files: ["registry/miami/ui/fade-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "file-tree": {
      name: "file-tree",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/file-tree")),
      source: "",
      files: ["registry/miami/ui/file-tree.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "flip-text": {
      name: "flip-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/flip-text")),
      source: "",
      files: ["registry/miami/ui/flip-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "form": {
      name: "form",
      type: "components:ui",
      registryDependencies: ["button","label"],
      component: React.lazy(() => import("~/registry/miami/ui/form")),
      source: "",
      files: ["registry/miami/ui/form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "globe": {
      name: "globe",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/globe")),
      source: "",
      files: ["registry/miami/ui/globe.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gradual-spacing": {
      name: "gradual-spacing",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/gradual-spacing")),
      source: "",
      files: ["registry/miami/ui/gradual-spacing.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern": {
      name: "grid-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/grid-pattern")),
      source: "",
      files: ["registry/miami/ui/grid-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gauge-circle": {
      name: "gauge-circle",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/gauge-circle")),
      source: "",
      files: ["registry/miami/ui/gauge-circle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hero-video": {
      name: "hero-video",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/hero-video")),
      source: "",
      files: ["registry/miami/ui/hero-video.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hover-card": {
      name: "hover-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/hover-card")),
      source: "",
      files: ["registry/miami/ui/hover-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "icon-cloud": {
      name: "icon-cloud",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/icon-cloud")),
      source: "",
      files: ["registry/miami/ui/icon-cloud.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input": {
      name: "input",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/input")),
      source: "",
      files: ["registry/miami/ui/input.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp": {
      name: "input-otp",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/input-otp")),
      source: "",
      files: ["registry/miami/ui/input-otp.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone": {
      name: "input-phone",
      type: "components:ui",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/miami/ui/input-phone")),
      source: "",
      files: ["registry/miami/ui/input-phone.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "label": {
      name: "label",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/label")),
      source: "",
      files: ["registry/miami/ui/label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "letter-pullup": {
      name: "letter-pullup",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/letter-pullup")),
      source: "",
      files: ["registry/miami/ui/letter-pullup.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "linear-gradient": {
      name: "linear-gradient",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/linear-gradient")),
      source: "",
      files: ["registry/miami/ui/linear-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "magic-card": {
      name: "magic-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/magic-card")),
      source: "",
      files: ["registry/miami/ui/magic-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee": {
      name: "marquee",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/marquee")),
      source: "",
      files: ["registry/miami/ui/marquee.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "menubar": {
      name: "menubar",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/menubar")),
      source: "",
      files: ["registry/miami/ui/menubar.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "meteors": {
      name: "meteors",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/meteors")),
      source: "",
      files: ["registry/miami/ui/meteors.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select": {
      name: "multi-select",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/multi-select")),
      source: "",
      files: ["registry/miami/ui/multi-select.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "navigation-menu": {
      name: "navigation-menu",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/navigation-menu")),
      source: "",
      files: ["registry/miami/ui/navigation-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "neon-gradient-card": {
      name: "neon-gradient-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/neon-gradient-card")),
      source: "",
      files: ["registry/miami/ui/neon-gradient-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "number-ticker": {
      name: "number-ticker",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/number-ticker")),
      source: "",
      files: ["registry/miami/ui/number-ticker.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "orbiting-circles": {
      name: "orbiting-circles",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/orbiting-circles")),
      source: "",
      files: ["registry/miami/ui/orbiting-circles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pagination": {
      name: "pagination",
      type: "components:ui",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/ui/pagination")),
      source: "",
      files: ["registry/miami/ui/pagination.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "particles": {
      name: "particles",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/particles")),
      source: "",
      files: ["registry/miami/ui/particles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "popover": {
      name: "popover",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/popover")),
      source: "",
      files: ["registry/miami/ui/popover.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "progress": {
      name: "progress",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/progress")),
      source: "",
      files: ["registry/miami/ui/progress.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pulsating-button": {
      name: "pulsating-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/pulsating-button")),
      source: "",
      files: ["registry/miami/ui/pulsating-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radio-group": {
      name: "radio-group",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/radio-group")),
      source: "",
      files: ["registry/miami/ui/radio-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radial-gradient": {
      name: "radial-gradient",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/radial-gradient")),
      source: "",
      files: ["registry/miami/ui/radial-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable": {
      name: "resizable",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/resizable")),
      source: "",
      files: ["registry/miami/ui/resizable.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "retro-grid": {
      name: "retro-grid",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/retro-grid")),
      source: "",
      files: ["registry/miami/ui/retro-grid.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "ripple": {
      name: "ripple",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/ripple")),
      source: "",
      files: ["registry/miami/ui/ripple.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-area": {
      name: "scroll-area",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/scroll-area")),
      source: "",
      files: ["registry/miami/ui/scroll-area.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-based-velocity": {
      name: "scroll-based-velocity",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/scroll-based-velocity")),
      source: "",
      files: ["registry/miami/ui/scroll-based-velocity.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-progress": {
      name: "scroll-progress",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/scroll-progress")),
      source: "",
      files: ["registry/miami/ui/scroll-progress.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select": {
      name: "select",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/select")),
      source: "",
      files: ["registry/miami/ui/select.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separate-away": {
      name: "separate-away",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/separate-away")),
      source: "",
      files: ["registry/miami/ui/separate-away.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separator": {
      name: "separator",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/separator")),
      source: "",
      files: ["registry/miami/ui/separator.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sheet": {
      name: "sheet",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/sheet")),
      source: "",
      files: ["registry/miami/ui/sheet.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shimmer-button": {
      name: "shimmer-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/shimmer-button")),
      source: "",
      files: ["registry/miami/ui/shimmer-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shine-border": {
      name: "shine-border",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/shine-border")),
      source: "",
      files: ["registry/miami/ui/shine-border.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shiny-button": {
      name: "shiny-button",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/shiny-button")),
      source: "",
      files: ["registry/miami/ui/shiny-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "skeleton": {
      name: "skeleton",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/skeleton")),
      source: "",
      files: ["registry/miami/ui/skeleton.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider": {
      name: "slider",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/slider")),
      source: "",
      files: ["registry/miami/ui/slider.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sonner": {
      name: "sonner",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/sonner")),
      source: "",
      files: ["registry/miami/ui/sonner.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sparkles-text": {
      name: "sparkles-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/sparkles-text")),
      source: "",
      files: ["registry/miami/ui/sparkles-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating": {
      name: "star-rating",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/star-rating")),
      source: "",
      files: ["registry/miami/ui/star-rating.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "switch": {
      name: "switch",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/switch")),
      source: "",
      files: ["registry/miami/ui/switch.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper": {
      name: "stepper",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/stepper")),
      source: "",
      files: ["registry/miami/ui/stepper.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scratch-to-reveal": {
      name: "scratch-to-reveal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/scratch-to-reveal")),
      source: "",
      files: ["registry/miami/ui/scratch-to-reveal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "table": {
      name: "table",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/table")),
      source: "",
      files: ["registry/miami/ui/table.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tabs": {
      name: "tabs",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/tabs")),
      source: "",
      files: ["registry/miami/ui/tabs.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea": {
      name: "textarea",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/textarea")),
      source: "",
      files: ["registry/miami/ui/textarea.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "text-reveal": {
      name: "text-reveal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/text-reveal")),
      source: "",
      files: ["registry/miami/ui/text-reveal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline": {
      name: "timeline",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/timeline")),
      source: "",
      files: ["registry/miami/ui/timeline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc": {
      name: "toc",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/toc")),
      source: "",
      files: ["registry/miami/ui/toc.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast": {
      name: "toast",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/toast")),
      source: "",
      files: ["registry/miami/ui/toast.tsx","registry/miami/ui/use-toast.ts","registry/miami/ui/toaster.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle": {
      name: "toggle",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/toggle")),
      source: "",
      files: ["registry/miami/ui/toggle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group": {
      name: "toggle-group",
      type: "components:ui",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/miami/ui/toggle-group")),
      source: "",
      files: ["registry/miami/ui/toggle-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip": {
      name: "tooltip",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/tooltip")),
      source: "",
      files: ["registry/miami/ui/tooltip.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tour": {
      name: "tour",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/tour")),
      source: "",
      files: ["registry/miami/ui/tour.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typing-animation": {
      name: "typing-animation",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/typing-animation")),
      source: "",
      files: ["registry/miami/ui/typing-animation.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card": {
      name: "tweet-card",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/tweet-card")),
      source: "",
      files: ["registry/miami/ui/tweet-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "video-modal": {
      name: "video-modal",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/video-modal")),
      source: "",
      files: ["registry/miami/ui/video-modal.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-dot-pattern": {
      name: "wavy-dot-pattern",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/wavy-dot-pattern")),
      source: "",
      files: ["registry/miami/ui/wavy-dot-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-text": {
      name: "wavy-text",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/wavy-text")),
      source: "",
      files: ["registry/miami/ui/wavy-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-fade-in": {
      name: "word-fade-in",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/word-fade-in")),
      source: "",
      files: ["registry/miami/ui/word-fade-in.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-pull-up": {
      name: "word-pull-up",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/word-pull-up")),
      source: "",
      files: ["registry/miami/ui/word-pull-up.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-rotate": {
      name: "word-rotate",
      type: "components:ui",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/ui/word-rotate")),
      source: "",
      files: ["registry/miami/ui/word-rotate.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "accordion-demo": {
      name: "accordion-demo",
      type: "components:example",
      registryDependencies: ["accordion"],
      component: React.lazy(() => import("~/registry/miami/example/accordion-demo")),
      source: "",
      files: ["registry/miami/example/accordion-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-demo": {
      name: "alert-demo",
      type: "components:example",
      registryDependencies: ["alert"],
      component: React.lazy(() => import("~/registry/miami/example/alert-demo")),
      source: "",
      files: ["registry/miami/example/alert-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-destructive": {
      name: "alert-destructive",
      type: "components:example",
      registryDependencies: ["alert"],
      component: React.lazy(() => import("~/registry/miami/example/alert-destructive")),
      source: "",
      files: ["registry/miami/example/alert-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "alert-dialog-demo": {
      name: "alert-dialog-demo",
      type: "components:example",
      registryDependencies: ["alert-dialog","button"],
      component: React.lazy(() => import("~/registry/miami/example/alert-dialog-demo")),
      source: "",
      files: ["registry/miami/example/alert-dialog-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "aspect-ratio-demo": {
      name: "aspect-ratio-demo",
      type: "components:example",
      registryDependencies: ["aspect-ratio"],
      component: React.lazy(() => import("~/registry/miami/example/aspect-ratio-demo")),
      source: "",
      files: ["registry/miami/example/aspect-ratio-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar-demo": {
      name: "avatar-demo",
      type: "components:example",
      registryDependencies: ["avatar"],
      component: React.lazy(() => import("~/registry/miami/example/avatar-demo")),
      source: "",
      files: ["registry/miami/example/avatar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-demo": {
      name: "badge-demo",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/miami/example/badge-demo")),
      source: "",
      files: ["registry/miami/example/badge-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-destructive": {
      name: "badge-destructive",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/miami/example/badge-destructive")),
      source: "",
      files: ["registry/miami/example/badge-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-outline": {
      name: "badge-outline",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/miami/example/badge-outline")),
      source: "",
      files: ["registry/miami/example/badge-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "badge-secondary": {
      name: "badge-secondary",
      type: "components:example",
      registryDependencies: ["badge"],
      component: React.lazy(() => import("~/registry/miami/example/badge-secondary")),
      source: "",
      files: ["registry/miami/example/badge-secondary.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-demo": {
      name: "breadcrumb-demo",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/miami/example/breadcrumb-demo")),
      source: "",
      files: ["registry/miami/example/breadcrumb-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-separator": {
      name: "breadcrumb-separator",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/miami/example/breadcrumb-separator")),
      source: "",
      files: ["registry/miami/example/breadcrumb-separator.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-dropdown": {
      name: "breadcrumb-dropdown",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/miami/example/breadcrumb-dropdown")),
      source: "",
      files: ["registry/miami/example/breadcrumb-dropdown.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-ellipsis": {
      name: "breadcrumb-ellipsis",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/miami/example/breadcrumb-ellipsis")),
      source: "",
      files: ["registry/miami/example/breadcrumb-ellipsis.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-link": {
      name: "breadcrumb-link",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/miami/example/breadcrumb-link")),
      source: "",
      files: ["registry/miami/example/breadcrumb-link.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "breadcrumb-responsive": {
      name: "breadcrumb-responsive",
      type: "components:example",
      registryDependencies: ["breadcrumb"],
      component: React.lazy(() => import("~/registry/miami/example/breadcrumb-responsive")),
      source: "",
      files: ["registry/miami/example/breadcrumb-responsive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-demo": {
      name: "button-demo",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-demo")),
      source: "",
      files: ["registry/miami/example/button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-secondary": {
      name: "button-secondary",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-secondary")),
      source: "",
      files: ["registry/miami/example/button-secondary.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-destructive": {
      name: "button-destructive",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-destructive")),
      source: "",
      files: ["registry/miami/example/button-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-outline": {
      name: "button-outline",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-outline")),
      source: "",
      files: ["registry/miami/example/button-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-ghost": {
      name: "button-ghost",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-ghost")),
      source: "",
      files: ["registry/miami/example/button-ghost.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-link": {
      name: "button-link",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-link")),
      source: "",
      files: ["registry/miami/example/button-link.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-with-icon": {
      name: "button-with-icon",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-with-icon")),
      source: "",
      files: ["registry/miami/example/button-with-icon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-loading": {
      name: "button-loading",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-loading")),
      source: "",
      files: ["registry/miami/example/button-loading.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-icon": {
      name: "button-icon",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-icon")),
      source: "",
      files: ["registry/miami/example/button-icon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "button-as-child": {
      name: "button-as-child",
      type: "components:example",
      registryDependencies: ["button"],
      component: React.lazy(() => import("~/registry/miami/example/button-as-child")),
      source: "",
      files: ["registry/miami/example/button-as-child.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pulsating-button-demo": {
      name: "pulsating-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/pulsating-button-demo")),
      source: "",
      files: ["registry/miami/example/pulsating-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "calendar-demo": {
      name: "calendar-demo",
      type: "components:example",
      registryDependencies: ["calendar"],
      component: React.lazy(() => import("~/registry/miami/example/calendar-demo")),
      source: "",
      files: ["registry/miami/example/calendar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "calendar-form": {
      name: "calendar-form",
      type: "components:example",
      registryDependencies: ["calendar","form","popover"],
      component: React.lazy(() => import("~/registry/miami/example/calendar-form")),
      source: "",
      files: ["registry/miami/example/calendar-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "card-demo": {
      name: "card-demo",
      type: "components:example",
      registryDependencies: ["card","button","switch"],
      component: React.lazy(() => import("~/registry/miami/example/card-demo")),
      source: "",
      files: ["registry/miami/example/card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "card-with-form": {
      name: "card-with-form",
      type: "components:example",
      registryDependencies: ["button","card","input","label","select"],
      component: React.lazy(() => import("~/registry/miami/example/card-with-form")),
      source: "",
      files: ["registry/miami/example/card-with-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-demo": {
      name: "carousel-demo",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/miami/example/carousel-demo")),
      source: "",
      files: ["registry/miami/example/carousel-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-size": {
      name: "carousel-size",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/miami/example/carousel-size")),
      source: "",
      files: ["registry/miami/example/carousel-size.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-spacing": {
      name: "carousel-spacing",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/miami/example/carousel-spacing")),
      source: "",
      files: ["registry/miami/example/carousel-spacing.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-orientation": {
      name: "carousel-orientation",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/miami/example/carousel-orientation")),
      source: "",
      files: ["registry/miami/example/carousel-orientation.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-dots": {
      name: "carousel-dots",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/miami/example/carousel-dots")),
      source: "",
      files: ["registry/miami/example/carousel-dots.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-api": {
      name: "carousel-api",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/miami/example/carousel-api")),
      source: "",
      files: ["registry/miami/example/carousel-api.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "carousel-plugin": {
      name: "carousel-plugin",
      type: "components:example",
      registryDependencies: ["carousel"],
      component: React.lazy(() => import("~/registry/miami/example/carousel-plugin")),
      source: "",
      files: ["registry/miami/example/carousel-plugin.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-demo": {
      name: "checkbox-demo",
      type: "components:example",
      registryDependencies: ["checkbox"],
      component: React.lazy(() => import("~/registry/miami/example/checkbox-demo")),
      source: "",
      files: ["registry/miami/example/checkbox-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-disabled": {
      name: "checkbox-disabled",
      type: "components:example",
      registryDependencies: ["checkbox"],
      component: React.lazy(() => import("~/registry/miami/example/checkbox-disabled")),
      source: "",
      files: ["registry/miami/example/checkbox-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-form-multiple": {
      name: "checkbox-form-multiple",
      type: "components:example",
      registryDependencies: ["checkbox","form"],
      component: React.lazy(() => import("~/registry/miami/example/checkbox-form-multiple")),
      source: "",
      files: ["registry/miami/example/checkbox-form-multiple.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-form-single": {
      name: "checkbox-form-single",
      type: "components:example",
      registryDependencies: ["checkbox","form"],
      component: React.lazy(() => import("~/registry/miami/example/checkbox-form-single")),
      source: "",
      files: ["registry/miami/example/checkbox-form-single.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "checkbox-with-text": {
      name: "checkbox-with-text",
      type: "components:example",
      registryDependencies: ["checkbox"],
      component: React.lazy(() => import("~/registry/miami/example/checkbox-with-text")),
      source: "",
      files: ["registry/miami/example/checkbox-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "collapsible-demo": {
      name: "collapsible-demo",
      type: "components:example",
      registryDependencies: ["collapsible"],
      component: React.lazy(() => import("~/registry/miami/example/collapsible-demo")),
      source: "",
      files: ["registry/miami/example/collapsible-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-demo": {
      name: "combobox-demo",
      type: "components:example",
      registryDependencies: ["command"],
      component: React.lazy(() => import("~/registry/miami/example/combobox-demo")),
      source: "",
      files: ["registry/miami/example/combobox-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-dropdown-menu": {
      name: "combobox-dropdown-menu",
      type: "components:example",
      registryDependencies: ["command","dropdown-menu","button"],
      component: React.lazy(() => import("~/registry/miami/example/combobox-dropdown-menu")),
      source: "",
      files: ["registry/miami/example/combobox-dropdown-menu.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-form": {
      name: "combobox-form",
      type: "components:example",
      registryDependencies: ["command","form"],
      component: React.lazy(() => import("~/registry/miami/example/combobox-form")),
      source: "",
      files: ["registry/miami/example/combobox-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-popover": {
      name: "combobox-popover",
      type: "components:example",
      registryDependencies: ["combobox","popover"],
      component: React.lazy(() => import("~/registry/miami/example/combobox-popover")),
      source: "",
      files: ["registry/miami/example/combobox-popover.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "combobox-responsive": {
      name: "combobox-responsive",
      type: "components:example",
      registryDependencies: ["combobox","popover","drawer"],
      component: React.lazy(() => import("~/registry/miami/example/combobox-responsive")),
      source: "",
      files: ["registry/miami/example/combobox-responsive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "command-demo": {
      name: "command-demo",
      type: "components:example",
      registryDependencies: ["command"],
      component: React.lazy(() => import("~/registry/miami/example/command-demo")),
      source: "",
      files: ["registry/miami/example/command-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "command-dialog": {
      name: "command-dialog",
      type: "components:example",
      registryDependencies: ["command","dialog"],
      component: React.lazy(() => import("~/registry/miami/example/command-dialog")),
      source: "",
      files: ["registry/miami/example/command-dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "context-menu-demo": {
      name: "context-menu-demo",
      type: "components:example",
      registryDependencies: ["context-menu"],
      component: React.lazy(() => import("~/registry/miami/example/context-menu-demo")),
      source: "",
      files: ["registry/miami/example/context-menu-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "data-table-demo": {
      name: "data-table-demo",
      type: "components:example",
      registryDependencies: ["data-table"],
      component: React.lazy(() => import("~/registry/miami/example/data-table-demo")),
      source: "",
      files: ["registry/miami/example/data-table-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-demo": {
      name: "date-picker-demo",
      type: "components:example",
      registryDependencies: ["button","calendar","popover"],
      component: React.lazy(() => import("~/registry/miami/example/date-picker-demo")),
      source: "",
      files: ["registry/miami/example/date-picker-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-form": {
      name: "date-picker-form",
      type: "components:example",
      registryDependencies: ["button","calendar","form","popover"],
      component: React.lazy(() => import("~/registry/miami/example/date-picker-form")),
      source: "",
      files: ["registry/miami/example/date-picker-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-with-presets": {
      name: "date-picker-with-presets",
      type: "components:example",
      registryDependencies: ["button","calendar","popover","select"],
      component: React.lazy(() => import("~/registry/miami/example/date-picker-with-presets")),
      source: "",
      files: ["registry/miami/example/date-picker-with-presets.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "date-picker-with-range": {
      name: "date-picker-with-range",
      type: "components:example",
      registryDependencies: ["button","calendar","popover"],
      component: React.lazy(() => import("~/registry/miami/example/date-picker-with-range")),
      source: "",
      files: ["registry/miami/example/date-picker-with-range.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dialog-demo": {
      name: "dialog-demo",
      type: "components:example",
      registryDependencies: ["dialog"],
      component: React.lazy(() => import("~/registry/miami/example/dialog-demo")),
      source: "",
      files: ["registry/miami/example/dialog-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dialog-close-button": {
      name: "dialog-close-button",
      type: "components:example",
      registryDependencies: ["dialog","button"],
      component: React.lazy(() => import("~/registry/miami/example/dialog-close-button")),
      source: "",
      files: ["registry/miami/example/dialog-close-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "drawer-demo": {
      name: "drawer-demo",
      type: "components:example",
      registryDependencies: ["drawer"],
      component: React.lazy(() => import("~/registry/miami/example/drawer-demo")),
      source: "",
      files: ["registry/miami/example/drawer-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "drawer-dialog": {
      name: "drawer-dialog",
      type: "components:example",
      registryDependencies: ["drawer","dialog"],
      component: React.lazy(() => import("~/registry/miami/example/drawer-dialog")),
      source: "",
      files: ["registry/miami/example/drawer-dialog.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu-demo": {
      name: "dropdown-menu-demo",
      type: "components:example",
      registryDependencies: ["dropdown-menu"],
      component: React.lazy(() => import("~/registry/miami/example/dropdown-menu-demo")),
      source: "",
      files: ["registry/miami/example/dropdown-menu-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu-checkboxes": {
      name: "dropdown-menu-checkboxes",
      type: "components:example",
      registryDependencies: ["dropdown-menu","checkbox"],
      component: React.lazy(() => import("~/registry/miami/example/dropdown-menu-checkboxes")),
      source: "",
      files: ["registry/miami/example/dropdown-menu-checkboxes.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dropdown-menu-radio-group": {
      name: "dropdown-menu-radio-group",
      type: "components:example",
      registryDependencies: ["dropdown-menu","radio-group"],
      component: React.lazy(() => import("~/registry/miami/example/dropdown-menu-radio-group")),
      source: "",
      files: ["registry/miami/example/dropdown-menu-radio-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hover-card-demo": {
      name: "hover-card-demo",
      type: "components:example",
      registryDependencies: ["hover-card"],
      component: React.lazy(() => import("~/registry/miami/example/hover-card-demo")),
      source: "",
      files: ["registry/miami/example/hover-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-demo": {
      name: "input-demo",
      type: "components:example",
      registryDependencies: ["input"],
      component: React.lazy(() => import("~/registry/miami/example/input-demo")),
      source: "",
      files: ["registry/miami/example/input-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-disabled": {
      name: "input-disabled",
      type: "components:example",
      registryDependencies: ["input"],
      component: React.lazy(() => import("~/registry/miami/example/input-disabled")),
      source: "",
      files: ["registry/miami/example/input-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-file": {
      name: "input-file",
      type: "components:example",
      registryDependencies: ["input"],
      component: React.lazy(() => import("~/registry/miami/example/input-file")),
      source: "",
      files: ["registry/miami/example/input-file.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-form": {
      name: "input-form",
      type: "components:example",
      registryDependencies: ["input","button","form"],
      component: React.lazy(() => import("~/registry/miami/example/input-form")),
      source: "",
      files: ["registry/miami/example/input-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-with-button": {
      name: "input-with-button",
      type: "components:example",
      registryDependencies: ["input","button"],
      component: React.lazy(() => import("~/registry/miami/example/input-with-button")),
      source: "",
      files: ["registry/miami/example/input-with-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-with-label": {
      name: "input-with-label",
      type: "components:example",
      registryDependencies: ["input","button","label"],
      component: React.lazy(() => import("~/registry/miami/example/input-with-label")),
      source: "",
      files: ["registry/miami/example/input-with-label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-with-text": {
      name: "input-with-text",
      type: "components:example",
      registryDependencies: ["input","button","label"],
      component: React.lazy(() => import("~/registry/miami/example/input-with-text")),
      source: "",
      files: ["registry/miami/example/input-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-demo": {
      name: "input-otp-demo",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/miami/example/input-otp-demo")),
      source: "",
      files: ["registry/miami/example/input-otp-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-pattern": {
      name: "input-otp-pattern",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/miami/example/input-otp-pattern")),
      source: "",
      files: ["registry/miami/example/input-otp-pattern.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-separator": {
      name: "input-otp-separator",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/miami/example/input-otp-separator")),
      source: "",
      files: ["registry/miami/example/input-otp-separator.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-controlled": {
      name: "input-otp-controlled",
      type: "components:example",
      registryDependencies: ["input-otp"],
      component: React.lazy(() => import("~/registry/miami/example/input-otp-controlled")),
      source: "",
      files: ["registry/miami/example/input-otp-controlled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-form": {
      name: "input-otp-form",
      type: "components:example",
      registryDependencies: ["input-otp","form"],
      component: React.lazy(() => import("~/registry/miami/example/input-otp-form")),
      source: "",
      files: ["registry/miami/example/input-otp-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-otp-disabled": {
      name: "input-otp-disabled",
      type: "components:example",
      registryDependencies: ["input-otp","form"],
      component: React.lazy(() => import("~/registry/miami/example/input-otp-disabled")),
      source: "",
      files: ["registry/miami/example/input-otp-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-default": {
      name: "input-phone-default",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/miami/example/input-phone-default")),
      source: "",
      files: ["registry/miami/example/input-phone-default.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-demo": {
      name: "input-phone-demo",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/miami/example/input-phone-demo")),
      source: "",
      files: ["registry/miami/example/input-phone-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-form": {
      name: "input-phone-form",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/miami/example/input-phone-form")),
      source: "",
      files: ["registry/miami/example/input-phone-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-initial": {
      name: "input-phone-initial",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/miami/example/input-phone-initial")),
      source: "",
      files: ["registry/miami/example/input-phone-initial.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-international": {
      name: "input-phone-international",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/miami/example/input-phone-international")),
      source: "",
      files: ["registry/miami/example/input-phone-international.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "input-phone-national": {
      name: "input-phone-national",
      type: "components:example",
      registryDependencies: ["button","input","popover"],
      component: React.lazy(() => import("~/registry/miami/example/input-phone-national")),
      source: "",
      files: ["registry/miami/example/input-phone-national.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "label-demo": {
      name: "label-demo",
      type: "components:example",
      registryDependencies: ["label"],
      component: React.lazy(() => import("~/registry/miami/example/label-demo")),
      source: "",
      files: ["registry/miami/example/label-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "menubar-demo": {
      name: "menubar-demo",
      type: "components:example",
      registryDependencies: ["menubar"],
      component: React.lazy(() => import("~/registry/miami/example/menubar-demo")),
      source: "",
      files: ["registry/miami/example/menubar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "navigation-menu-demo": {
      name: "navigation-menu-demo",
      type: "components:example",
      registryDependencies: ["navigation-menu"],
      component: React.lazy(() => import("~/registry/miami/example/navigation-menu-demo")),
      source: "",
      files: ["registry/miami/example/navigation-menu-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "pagination-demo": {
      name: "pagination-demo",
      type: "components:example",
      registryDependencies: ["pagination"],
      component: React.lazy(() => import("~/registry/miami/example/pagination-demo")),
      source: "",
      files: ["registry/miami/example/pagination-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "popover-demo": {
      name: "popover-demo",
      type: "components:example",
      registryDependencies: ["popover"],
      component: React.lazy(() => import("~/registry/miami/example/popover-demo")),
      source: "",
      files: ["registry/miami/example/popover-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "progress-demo": {
      name: "progress-demo",
      type: "components:example",
      registryDependencies: ["progress"],
      component: React.lazy(() => import("~/registry/miami/example/progress-demo")),
      source: "",
      files: ["registry/miami/example/progress-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radio-group-demo": {
      name: "radio-group-demo",
      type: "components:example",
      registryDependencies: ["radio-group"],
      component: React.lazy(() => import("~/registry/miami/example/radio-group-demo")),
      source: "",
      files: ["registry/miami/example/radio-group-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radio-group-form": {
      name: "radio-group-form",
      type: "components:example",
      registryDependencies: ["radio-group","form"],
      component: React.lazy(() => import("~/registry/miami/example/radio-group-form")),
      source: "",
      files: ["registry/miami/example/radio-group-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-demo": {
      name: "resizable-demo",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/miami/example/resizable-demo")),
      source: "",
      files: ["registry/miami/example/resizable-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-demo-with-handle": {
      name: "resizable-demo-with-handle",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/miami/example/resizable-demo-with-handle")),
      source: "",
      files: ["registry/miami/example/resizable-demo-with-handle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-vertical": {
      name: "resizable-vertical",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/miami/example/resizable-vertical")),
      source: "",
      files: ["registry/miami/example/resizable-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "resizable-handle": {
      name: "resizable-handle",
      type: "components:example",
      registryDependencies: ["resizable"],
      component: React.lazy(() => import("~/registry/miami/example/resizable-handle")),
      source: "",
      files: ["registry/miami/example/resizable-handle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-area-demo": {
      name: "scroll-area-demo",
      type: "components:example",
      registryDependencies: ["scroll-area"],
      component: React.lazy(() => import("~/registry/miami/example/scroll-area-demo")),
      source: "",
      files: ["registry/miami/example/scroll-area-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-area-horizontal-demo": {
      name: "scroll-area-horizontal-demo",
      type: "components:example",
      registryDependencies: ["scroll-area"],
      component: React.lazy(() => import("~/registry/miami/example/scroll-area-horizontal-demo")),
      source: "",
      files: ["registry/miami/example/scroll-area-horizontal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select-demo": {
      name: "select-demo",
      type: "components:example",
      registryDependencies: ["select"],
      component: React.lazy(() => import("~/registry/miami/example/select-demo")),
      source: "",
      files: ["registry/miami/example/select-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select-scrollable": {
      name: "select-scrollable",
      type: "components:example",
      registryDependencies: ["select"],
      component: React.lazy(() => import("~/registry/miami/example/select-scrollable")),
      source: "",
      files: ["registry/miami/example/select-scrollable.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "select-form": {
      name: "select-form",
      type: "components:example",
      registryDependencies: ["select"],
      component: React.lazy(() => import("~/registry/miami/example/select-form")),
      source: "",
      files: ["registry/miami/example/select-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separator-demo": {
      name: "separator-demo",
      type: "components:example",
      registryDependencies: ["separator"],
      component: React.lazy(() => import("~/registry/miami/example/separator-demo")),
      source: "",
      files: ["registry/miami/example/separator-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sheet-demo": {
      name: "sheet-demo",
      type: "components:example",
      registryDependencies: ["sheet"],
      component: React.lazy(() => import("~/registry/miami/example/sheet-demo")),
      source: "",
      files: ["registry/miami/example/sheet-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sheet-side": {
      name: "sheet-side",
      type: "components:example",
      registryDependencies: ["sheet"],
      component: React.lazy(() => import("~/registry/miami/example/sheet-side")),
      source: "",
      files: ["registry/miami/example/sheet-side.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "skeleton-demo": {
      name: "skeleton-demo",
      type: "components:example",
      registryDependencies: ["skeleton"],
      component: React.lazy(() => import("~/registry/miami/example/skeleton-demo")),
      source: "",
      files: ["registry/miami/example/skeleton-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "skeleton-card": {
      name: "skeleton-card",
      type: "components:example",
      registryDependencies: ["skeleton"],
      component: React.lazy(() => import("~/registry/miami/example/skeleton-card")),
      source: "",
      files: ["registry/miami/example/skeleton-card.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-demo": {
      name: "slider-demo",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-demo")),
      source: "",
      files: ["registry/miami/example/slider-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-disabled": {
      name: "slider-disabled",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-disabled")),
      source: "",
      files: ["registry/miami/example/slider-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-inverted": {
      name: "slider-inverted",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-inverted")),
      source: "",
      files: ["registry/miami/example/slider-inverted.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-vertical": {
      name: "slider-vertical",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-vertical")),
      source: "",
      files: ["registry/miami/example/slider-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-range": {
      name: "slider-range",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-range")),
      source: "",
      files: ["registry/miami/example/slider-range.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-tick-marks-half": {
      name: "slider-tick-marks-half",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-tick-marks-half")),
      source: "",
      files: ["registry/miami/example/slider-tick-marks-half.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-tick-marks-full": {
      name: "slider-tick-marks-full",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-tick-marks-full")),
      source: "",
      files: ["registry/miami/example/slider-tick-marks-full.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-format-label": {
      name: "slider-format-label",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-format-label")),
      source: "",
      files: ["registry/miami/example/slider-format-label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "slider-format-label-side": {
      name: "slider-format-label-side",
      type: "components:example",
      registryDependencies: ["slider"],
      component: React.lazy(() => import("~/registry/miami/example/slider-format-label-side")),
      source: "",
      files: ["registry/miami/example/slider-format-label-side.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sonner-demo": {
      name: "sonner-demo",
      type: "components:example",
      registryDependencies: ["sonner"],
      component: React.lazy(() => import("~/registry/miami/example/sonner-demo")),
      source: "",
      files: ["registry/miami/example/sonner-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "switch-demo": {
      name: "switch-demo",
      type: "components:example",
      registryDependencies: ["switch"],
      component: React.lazy(() => import("~/registry/miami/example/switch-demo")),
      source: "",
      files: ["registry/miami/example/switch-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "switch-form": {
      name: "switch-form",
      type: "components:example",
      registryDependencies: ["switch","form"],
      component: React.lazy(() => import("~/registry/miami/example/switch-form")),
      source: "",
      files: ["registry/miami/example/switch-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "table-demo": {
      name: "table-demo",
      type: "components:example",
      registryDependencies: ["table"],
      component: React.lazy(() => import("~/registry/miami/example/table-demo")),
      source: "",
      files: ["registry/miami/example/table-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tabs-demo": {
      name: "tabs-demo",
      type: "components:example",
      registryDependencies: ["tabs"],
      component: React.lazy(() => import("~/registry/miami/example/tabs-demo")),
      source: "",
      files: ["registry/miami/example/tabs-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-demo": {
      name: "textarea-demo",
      type: "components:example",
      registryDependencies: ["textarea"],
      component: React.lazy(() => import("~/registry/miami/example/textarea-demo")),
      source: "",
      files: ["registry/miami/example/textarea-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-disabled": {
      name: "textarea-disabled",
      type: "components:example",
      registryDependencies: ["textarea"],
      component: React.lazy(() => import("~/registry/miami/example/textarea-disabled")),
      source: "",
      files: ["registry/miami/example/textarea-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-form": {
      name: "textarea-form",
      type: "components:example",
      registryDependencies: ["textarea","form"],
      component: React.lazy(() => import("~/registry/miami/example/textarea-form")),
      source: "",
      files: ["registry/miami/example/textarea-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-with-button": {
      name: "textarea-with-button",
      type: "components:example",
      registryDependencies: ["textarea","button"],
      component: React.lazy(() => import("~/registry/miami/example/textarea-with-button")),
      source: "",
      files: ["registry/miami/example/textarea-with-button.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-with-label": {
      name: "textarea-with-label",
      type: "components:example",
      registryDependencies: ["textarea","label"],
      component: React.lazy(() => import("~/registry/miami/example/textarea-with-label")),
      source: "",
      files: ["registry/miami/example/textarea-with-label.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "textarea-with-text": {
      name: "textarea-with-text",
      type: "components:example",
      registryDependencies: ["textarea","label"],
      component: React.lazy(() => import("~/registry/miami/example/textarea-with-text")),
      source: "",
      files: ["registry/miami/example/textarea-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-demo": {
      name: "toast-demo",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/miami/example/toast-demo")),
      source: "",
      files: ["registry/miami/example/toast-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-destructive": {
      name: "toast-destructive",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/miami/example/toast-destructive")),
      source: "",
      files: ["registry/miami/example/toast-destructive.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-simple": {
      name: "toast-simple",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/miami/example/toast-simple")),
      source: "",
      files: ["registry/miami/example/toast-simple.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-with-action": {
      name: "toast-with-action",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/miami/example/toast-with-action")),
      source: "",
      files: ["registry/miami/example/toast-with-action.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toast-with-title": {
      name: "toast-with-title",
      type: "components:example",
      registryDependencies: ["toast"],
      component: React.lazy(() => import("~/registry/miami/example/toast-with-title")),
      source: "",
      files: ["registry/miami/example/toast-with-title.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-demo": {
      name: "toggle-group-demo",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-group-demo")),
      source: "",
      files: ["registry/miami/example/toggle-group-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-disabled": {
      name: "toggle-group-disabled",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-group-disabled")),
      source: "",
      files: ["registry/miami/example/toggle-group-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-lg": {
      name: "toggle-group-lg",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-group-lg")),
      source: "",
      files: ["registry/miami/example/toggle-group-lg.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-outline": {
      name: "toggle-group-outline",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-group-outline")),
      source: "",
      files: ["registry/miami/example/toggle-group-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-sm": {
      name: "toggle-group-sm",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-group-sm")),
      source: "",
      files: ["registry/miami/example/toggle-group-sm.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-group-single": {
      name: "toggle-group-single",
      type: "components:example",
      registryDependencies: ["toggle-group"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-group-single")),
      source: "",
      files: ["registry/miami/example/toggle-group-single.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-demo": {
      name: "toggle-demo",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-demo")),
      source: "",
      files: ["registry/miami/example/toggle-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-disabled": {
      name: "toggle-disabled",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-disabled")),
      source: "",
      files: ["registry/miami/example/toggle-disabled.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-lg": {
      name: "toggle-lg",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-lg")),
      source: "",
      files: ["registry/miami/example/toggle-lg.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-outline": {
      name: "toggle-outline",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-outline")),
      source: "",
      files: ["registry/miami/example/toggle-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-sm": {
      name: "toggle-sm",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-sm")),
      source: "",
      files: ["registry/miami/example/toggle-sm.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toggle-with-text": {
      name: "toggle-with-text",
      type: "components:example",
      registryDependencies: ["toggle"],
      component: React.lazy(() => import("~/registry/miami/example/toggle-with-text")),
      source: "",
      files: ["registry/miami/example/toggle-with-text.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "mode-toggle-group": {
      name: "mode-toggle-group",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/mode-toggle-group")),
      source: "",
      files: ["registry/miami/example/mode-toggle-group.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-demo": {
      name: "tooltip-demo",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/miami/example/tooltip-demo")),
      source: "",
      files: ["registry/miami/example/tooltip-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-outline": {
      name: "tooltip-outline",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/miami/example/tooltip-outline")),
      source: "",
      files: ["registry/miami/example/tooltip-outline.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-secondary": {
      name: "tooltip-secondary",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/miami/example/tooltip-secondary")),
      source: "",
      files: ["registry/miami/example/tooltip-secondary.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tooltip-with-toggle-demo": {
      name: "tooltip-with-toggle-demo",
      type: "components:example",
      registryDependencies: ["tooltip"],
      component: React.lazy(() => import("~/registry/miami/example/tooltip-with-toggle-demo")),
      source: "",
      files: ["registry/miami/example/tooltip-with-toggle-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-blockquote": {
      name: "typography-blockquote",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-blockquote")),
      source: "",
      files: ["registry/miami/example/typography-blockquote.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-demo": {
      name: "typography-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-demo")),
      source: "",
      files: ["registry/miami/example/typography-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h1": {
      name: "typography-h1",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-h1")),
      source: "",
      files: ["registry/miami/example/typography-h1.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h2": {
      name: "typography-h2",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-h2")),
      source: "",
      files: ["registry/miami/example/typography-h2.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h3": {
      name: "typography-h3",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-h3")),
      source: "",
      files: ["registry/miami/example/typography-h3.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-h4": {
      name: "typography-h4",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-h4")),
      source: "",
      files: ["registry/miami/example/typography-h4.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-inline-code": {
      name: "typography-inline-code",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-inline-code")),
      source: "",
      files: ["registry/miami/example/typography-inline-code.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-large": {
      name: "typography-large",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-large")),
      source: "",
      files: ["registry/miami/example/typography-large.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-lead": {
      name: "typography-lead",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-lead")),
      source: "",
      files: ["registry/miami/example/typography-lead.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-list": {
      name: "typography-list",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-list")),
      source: "",
      files: ["registry/miami/example/typography-list.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-muted": {
      name: "typography-muted",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-muted")),
      source: "",
      files: ["registry/miami/example/typography-muted.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-p": {
      name: "typography-p",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-p")),
      source: "",
      files: ["registry/miami/example/typography-p.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-small": {
      name: "typography-small",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-small")),
      source: "",
      files: ["registry/miami/example/typography-small.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typography-table": {
      name: "typography-table",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typography-table")),
      source: "",
      files: ["registry/miami/example/typography-table.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "mode-toggle": {
      name: "mode-toggle",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/mode-toggle")),
      source: "",
      files: ["registry/miami/example/mode-toggle.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cards": {
      name: "cards",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/cards")),
      source: "",
      files: ["registry/miami/example/cards/cards.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo": {
      name: "chart-bar-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/chart-bar-demo")),
      source: "",
      files: ["registry/miami/example/chart-bar-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-grid": {
      name: "chart-bar-demo-grid",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/chart-bar-demo-grid")),
      source: "",
      files: ["registry/miami/example/chart-bar-demo-grid.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-axis": {
      name: "chart-bar-demo-axis",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/chart-bar-demo-axis")),
      source: "",
      files: ["registry/miami/example/chart-bar-demo-axis.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-tooltip": {
      name: "chart-bar-demo-tooltip",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/chart-bar-demo-tooltip")),
      source: "",
      files: ["registry/miami/example/chart-bar-demo-tooltip.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-bar-demo-legend": {
      name: "chart-bar-demo-legend",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/chart-bar-demo-legend")),
      source: "",
      files: ["registry/miami/example/chart-bar-demo-legend.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "chart-tooltip-demo": {
      name: "chart-tooltip-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/chart-tooltip-demo")),
      source: "",
      files: ["registry/miami/example/chart-tooltip-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "magic-card-demo": {
      name: "magic-card-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/magic-card-demo")),
      source: "",
      files: ["registry/miami/example/magic-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "neon-gradient-card-demo": {
      name: "neon-gradient-card-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/neon-gradient-card-demo")),
      source: "",
      files: ["registry/miami/example/neon-gradient-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "magic-card-gradient": {
      name: "magic-card-gradient",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/magic-card-gradient")),
      source: "",
      files: ["registry/miami/example/magic-card-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "linear-gradient-demo": {
      name: "linear-gradient-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/linear-gradient-demo")),
      source: "",
      files: ["registry/miami/example/linear-gradient-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "radial-gradient-demo": {
      name: "radial-gradient-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/radial-gradient-demo")),
      source: "",
      files: ["registry/miami/example/radial-gradient-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "meteors-demo": {
      name: "meteors-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/meteors-demo")),
      source: "",
      files: ["registry/miami/example/meteors-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern-demo": {
      name: "grid-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/grid-pattern-demo")),
      source: "",
      files: ["registry/miami/example/grid-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern-linear-gradient": {
      name: "grid-pattern-linear-gradient",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/grid-pattern-linear-gradient")),
      source: "",
      files: ["registry/miami/example/grid-pattern-linear-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "grid-pattern-dashed": {
      name: "grid-pattern-dashed",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/grid-pattern-dashed")),
      source: "",
      files: ["registry/miami/example/grid-pattern-dashed.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dot-pattern-demo": {
      name: "dot-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/dot-pattern-demo")),
      source: "",
      files: ["registry/miami/example/dot-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dot-pattern-linear-gradient": {
      name: "dot-pattern-linear-gradient",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/dot-pattern-linear-gradient")),
      source: "",
      files: ["registry/miami/example/dot-pattern-linear-gradient.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-demo": {
      name: "marquee-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/marquee-demo")),
      source: "",
      files: ["registry/miami/example/marquee-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-demo-vertical": {
      name: "marquee-demo-vertical",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/marquee-demo-vertical")),
      source: "",
      files: ["registry/miami/example/marquee-demo-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-logos": {
      name: "marquee-logos",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/marquee-logos")),
      source: "",
      files: ["registry/miami/example/marquee-logos.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "marquee-3d": {
      name: "marquee-3d",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/marquee-3d")),
      source: "",
      files: ["registry/miami/example/marquee-3d.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "globe-demo": {
      name: "globe-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/globe-demo")),
      source: "",
      files: ["registry/miami/example/globe-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card-demo": {
      name: "tweet-card-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/tweet-card-demo")),
      source: "",
      files: ["registry/miami/example/tweet-card-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card-images": {
      name: "tweet-card-images",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/tweet-card-images")),
      source: "",
      files: ["registry/miami/example/tweet-card-images.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tweet-card-meta-preview": {
      name: "tweet-card-meta-preview",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/tweet-card-meta-preview")),
      source: "",
      files: ["registry/miami/example/tweet-card-meta-preview.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shimmer-button-demo": {
      name: "shimmer-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/shimmer-button-demo")),
      source: "",
      files: ["registry/miami/example/shimmer-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hero-video-demo": {
      name: "hero-video-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/hero-video-demo")),
      source: "",
      files: ["registry/miami/example/hero-video-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "hero-video-youtube": {
      name: "hero-video-youtube",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/hero-video-youtube")),
      source: "",
      files: ["registry/miami/example/hero-video-youtube.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "bento-demo": {
      name: "bento-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/bento-demo")),
      source: "",
      files: ["registry/miami/example/bento-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "bento-demo-vertical": {
      name: "bento-demo-vertical",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/bento-demo-vertical")),
      source: "",
      files: ["registry/miami/example/bento-demo-vertical.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "number-ticker-demo": {
      name: "number-ticker-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/number-ticker-demo")),
      source: "",
      files: ["registry/miami/example/number-ticker-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "ripple-demo": {
      name: "ripple-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/ripple-demo")),
      source: "",
      files: ["registry/miami/example/ripple-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "retro-grid-demo": {
      name: "retro-grid-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/retro-grid-demo")),
      source: "",
      files: ["registry/miami/example/retro-grid-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-list-demo": {
      name: "animated-list-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-list-demo")),
      source: "",
      files: ["registry/miami/example/animated-list-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-lines-demo": {
      name: "animated-lines-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-lines-demo")),
      source: "",
      files: ["registry/miami/example/animated-lines-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-shiny-text-demo": {
      name: "animated-shiny-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-shiny-text-demo")),
      source: "",
      files: ["registry/miami/example/animated-shiny-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "particles-demo": {
      name: "particles-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/particles-demo")),
      source: "",
      files: ["registry/miami/example/particles-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-grid-pattern-demo": {
      name: "animated-grid-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-grid-pattern-demo")),
      source: "",
      files: ["registry/miami/example/animated-grid-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "border-beam-demo": {
      name: "border-beam-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/border-beam-demo")),
      source: "",
      files: ["registry/miami/example/border-beam-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-demo": {
      name: "animated-beam-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-beam-demo")),
      source: "",
      files: ["registry/miami/example/animated-beam-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-unidirectional": {
      name: "animated-beam-unidirectional",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-beam-unidirectional")),
      source: "",
      files: ["registry/miami/example/animated-beam-unidirectional.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-bidirectional": {
      name: "animated-beam-bidirectional",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-beam-bidirectional")),
      source: "",
      files: ["registry/miami/example/animated-beam-bidirectional.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-multiple-inputs": {
      name: "animated-beam-multiple-inputs",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-beam-multiple-inputs")),
      source: "",
      files: ["registry/miami/example/animated-beam-multiple-inputs.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-beam-multiple-outputs": {
      name: "animated-beam-multiple-outputs",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-beam-multiple-outputs")),
      source: "",
      files: ["registry/miami/example/animated-beam-multiple-outputs.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-1": {
      name: "animated-feature-card-1",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-1")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-1.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-2": {
      name: "animated-feature-card-2",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-2")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-2.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-3": {
      name: "animated-feature-card-3",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-3")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-3.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-4": {
      name: "animated-feature-card-4",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-4")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-4.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-5": {
      name: "animated-feature-card-5",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-5")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-5.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-6": {
      name: "animated-feature-card-6",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-6")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-6.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-7": {
      name: "animated-feature-card-7",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-7")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-7.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-8": {
      name: "animated-feature-card-8",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-8")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-8.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-9": {
      name: "animated-feature-card-9",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-9")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-9.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-feature-card-10": {
      name: "animated-feature-card-10",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-feature-card-10")),
      source: "",
      files: ["registry/miami/example/animated-feature-card-10.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "text-reveal-demo": {
      name: "text-reveal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/text-reveal-demo")),
      source: "",
      files: ["registry/miami/example/text-reveal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-gradient-text-demo": {
      name: "animated-gradient-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-gradient-text-demo")),
      source: "",
      files: ["registry/miami/example/animated-gradient-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "orbiting-circles-demo": {
      name: "orbiting-circles-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/orbiting-circles-demo")),
      source: "",
      files: ["registry/miami/example/orbiting-circles-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dock-demo": {
      name: "dock-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/dock-demo")),
      source: "",
      files: ["registry/miami/example/dock-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-rotate-demo": {
      name: "word-rotate-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/word-rotate-demo")),
      source: "",
      files: ["registry/miami/example/word-rotate-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "avatar-circles-demo": {
      name: "avatar-circles-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/avatar-circles-demo")),
      source: "",
      files: ["registry/miami/example/avatar-circles-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-pull-up-demo": {
      name: "word-pull-up-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/word-pull-up-demo")),
      source: "",
      files: ["registry/miami/example/word-pull-up-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "typing-animation-demo": {
      name: "typing-animation-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/typing-animation-demo")),
      source: "",
      files: ["registry/miami/example/typing-animation-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-text-demo": {
      name: "wavy-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/wavy-text-demo")),
      source: "",
      files: ["registry/miami/example/wavy-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-in-demo": {
      name: "blur-in-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/blur-in-demo")),
      source: "",
      files: ["registry/miami/example/blur-in-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "separate-away-demo": {
      name: "separate-away-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/separate-away-demo")),
      source: "",
      files: ["registry/miami/example/separate-away-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-based-velocity-demo": {
      name: "scroll-based-velocity-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/scroll-based-velocity-demo")),
      source: "",
      files: ["registry/miami/example/scroll-based-velocity-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "letter-pullup-demo": {
      name: "letter-pullup-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/letter-pullup-demo")),
      source: "",
      files: ["registry/miami/example/letter-pullup-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "flip-text-demo": {
      name: "flip-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/flip-text-demo")),
      source: "",
      files: ["registry/miami/example/flip-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "sparkles-text-demo": {
      name: "sparkles-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/sparkles-text-demo")),
      source: "",
      files: ["registry/miami/example/sparkles-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "icon-cloud-demo": {
      name: "icon-cloud-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/icon-cloud-demo")),
      source: "",
      files: ["registry/miami/example/icon-cloud-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "wavy-dot-pattern-demo": {
      name: "wavy-dot-pattern-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/wavy-dot-pattern-demo")),
      source: "",
      files: ["registry/miami/example/wavy-dot-pattern-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gradual-spacing-demo": {
      name: "gradual-spacing-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/gradual-spacing-demo")),
      source: "",
      files: ["registry/miami/example/gradual-spacing-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "word-fade-in-demo": {
      name: "word-fade-in-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/word-fade-in-demo")),
      source: "",
      files: ["registry/miami/example/word-fade-in-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "fade-text-demo": {
      name: "fade-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/fade-text-demo")),
      source: "",
      files: ["registry/miami/example/fade-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shiny-button-demo": {
      name: "shiny-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/shiny-button-demo")),
      source: "",
      files: ["registry/miami/example/shiny-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "box-reveal-demo": {
      name: "box-reveal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/box-reveal-demo")),
      source: "",
      files: ["registry/miami/example/box-reveal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "gauge-circle-demo": {
      name: "gauge-circle-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/gauge-circle-demo")),
      source: "",
      files: ["registry/miami/example/gauge-circle-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shine-border-demo": {
      name: "shine-border-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/shine-border-demo")),
      source: "",
      files: ["registry/miami/example/shine-border-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "shine-border-demo-2": {
      name: "shine-border-demo-2",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/shine-border-demo-2")),
      source: "",
      files: ["registry/miami/example/shine-border-demo-2.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-hover": {
      name: "confetti-hover",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-hover")),
      source: "",
      files: ["registry/miami/example/confetti-hover.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-basic-cannon": {
      name: "confetti-basic-cannon",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-basic-cannon")),
      source: "",
      files: ["registry/miami/example/confetti-basic-cannon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-random-direction": {
      name: "confetti-random-direction",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-random-direction")),
      source: "",
      files: ["registry/miami/example/confetti-random-direction.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-fireworks": {
      name: "confetti-fireworks",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-fireworks")),
      source: "",
      files: ["registry/miami/example/confetti-fireworks.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-stars": {
      name: "confetti-stars",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-stars")),
      source: "",
      files: ["registry/miami/example/confetti-stars.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-side-cannons": {
      name: "confetti-side-cannons",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-side-cannons")),
      source: "",
      files: ["registry/miami/example/confetti-side-cannons.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-custom-shapes": {
      name: "confetti-custom-shapes",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-custom-shapes")),
      source: "",
      files: ["registry/miami/example/confetti-custom-shapes.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-emoji": {
      name: "confetti-emoji",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-emoji")),
      source: "",
      files: ["registry/miami/example/confetti-emoji.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "animated-subscribe-button-demo": {
      name: "animated-subscribe-button-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/animated-subscribe-button-demo")),
      source: "",
      files: ["registry/miami/example/animated-subscribe-button-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cool-mode-demo": {
      name: "cool-mode-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/cool-mode-demo")),
      source: "",
      files: ["registry/miami/example/cool-mode-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "cool-mode-custom": {
      name: "cool-mode-custom",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/cool-mode-custom")),
      source: "",
      files: ["registry/miami/example/cool-mode-custom.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-demo": {
      name: "timeline-demo",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/miami/example/timeline-demo")),
      source: "",
      files: ["registry/miami/example/timeline-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-right": {
      name: "timeline-right",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/miami/example/timeline-right")),
      source: "",
      files: ["registry/miami/example/timeline-right.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-alternate": {
      name: "timeline-alternate",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/miami/example/timeline-alternate")),
      source: "",
      files: ["registry/miami/example/timeline-alternate.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "timeline-labels": {
      name: "timeline-labels",
      type: "components:example",
      registryDependencies: ["timeline"],
      component: React.lazy(() => import("~/registry/miami/example/timeline-labels")),
      source: "",
      files: ["registry/miami/example/timeline-labels.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating-demo": {
      name: "star-rating-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/star-rating-demo")),
      source: "",
      files: ["registry/miami/example/star-rating-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating-colored": {
      name: "star-rating-colored",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/star-rating-colored")),
      source: "",
      files: ["registry/miami/example/star-rating-colored.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "star-rating-icon": {
      name: "star-rating-icon",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/star-rating-icon")),
      source: "",
      files: ["registry/miami/example/star-rating-icon.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-demo": {
      name: "stepper-demo",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-demo")),
      source: "",
      files: ["registry/miami/example/stepper-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-custom-styles": {
      name: "stepper-custom-styles",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-custom-styles")),
      source: "",
      files: ["registry/miami/example/stepper-custom-styles.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-orientation": {
      name: "stepper-orientation",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-orientation")),
      source: "",
      files: ["registry/miami/example/stepper-orientation.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-description": {
      name: "stepper-description",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-description")),
      source: "",
      files: ["registry/miami/example/stepper-description.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-sizes": {
      name: "stepper-sizes",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-sizes")),
      source: "",
      files: ["registry/miami/example/stepper-sizes.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-form": {
      name: "stepper-form",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-form")),
      source: "",
      files: ["registry/miami/example/stepper-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-variants": {
      name: "stepper-variants",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-variants")),
      source: "",
      files: ["registry/miami/example/stepper-variants.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-custom-icons": {
      name: "stepper-custom-icons",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-custom-icons")),
      source: "",
      files: ["registry/miami/example/stepper-custom-icons.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-footer-inside": {
      name: "stepper-footer-inside",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-footer-inside")),
      source: "",
      files: ["registry/miami/example/stepper-footer-inside.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-scroll-tracking": {
      name: "stepper-scroll-tracking",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-scroll-tracking")),
      source: "",
      files: ["registry/miami/example/stepper-scroll-tracking.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-clickable-steps": {
      name: "stepper-clickable-steps",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-clickable-steps")),
      source: "",
      files: ["registry/miami/example/stepper-clickable-steps.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-optional-steps": {
      name: "stepper-optional-steps",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-optional-steps")),
      source: "",
      files: ["registry/miami/example/stepper-optional-steps.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "stepper-state": {
      name: "stepper-state",
      type: "components:example",
      registryDependencies: ["stepper"],
      component: React.lazy(() => import("~/registry/miami/example/stepper-state")),
      source: "",
      files: ["registry/miami/example/stepper-state.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "tour-demo": {
      name: "tour-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/tour-demo")),
      source: "",
      files: ["registry/miami/example/tour-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc-demo": {
      name: "toc-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/toc-demo")),
      source: "",
      files: ["registry/miami/example/toc-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc-nested": {
      name: "toc-nested",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/toc-nested")),
      source: "",
      files: ["registry/miami/example/toc-nested.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "toc-active": {
      name: "toc-active",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/toc-active")),
      source: "",
      files: ["registry/miami/example/toc-active.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-basic": {
      name: "multi-select-basic",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/miami/example/multi-select-basic")),
      source: "",
      files: ["registry/miami/example/multi-select-basic.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-filter": {
      name: "multi-select-filter",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/miami/example/multi-select-filter")),
      source: "",
      files: ["registry/miami/example/multi-select-filter.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-controllable-search": {
      name: "multi-select-controllable-search",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/miami/example/multi-select-controllable-search")),
      source: "",
      files: ["registry/miami/example/multi-select-controllable-search.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-input-custom": {
      name: "multi-select-input-custom",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/miami/example/multi-select-input-custom")),
      source: "",
      files: ["registry/miami/example/multi-select-input-custom.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-limit-count": {
      name: "multi-select-limit-count",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/miami/example/multi-select-limit-count")),
      source: "",
      files: ["registry/miami/example/multi-select-limit-count.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-limit-display-length": {
      name: "multi-select-limit-display-length",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/miami/example/multi-select-limit-display-length")),
      source: "",
      files: ["registry/miami/example/multi-select-limit-display-length.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "multi-select-form": {
      name: "multi-select-form",
      type: "components:example",
      registryDependencies: ["multi-select"],
      component: React.lazy(() => import("~/registry/miami/example/multi-select-form")),
      source: "",
      files: ["registry/miami/example/multi-select-form.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scroll-progress-demo": {
      name: "scroll-progress-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/scroll-progress-demo")),
      source: "",
      files: ["registry/miami/example/scroll-progress-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "video-modal-demo": {
      name: "video-modal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/video-modal-demo")),
      source: "",
      files: ["registry/miami/example/video-modal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "scratch-to-reveal-demo": {
      name: "scratch-to-reveal-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/scratch-to-reveal-demo")),
      source: "",
      files: ["registry/miami/example/scratch-to-reveal-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "confetti-demo": {
      name: "confetti-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/confetti-demo")),
      source: "",
      files: ["registry/miami/example/confetti-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-fade-demo": {
      name: "blur-fade-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/blur-fade-demo")),
      source: "",
      files: ["registry/miami/example/blur-fade-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "blur-fade-text-demo": {
      name: "blur-fade-text-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/blur-fade-text-demo")),
      source: "",
      files: ["registry/miami/example/blur-fade-text-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "file-tree-demo": {
      name: "file-tree-demo",
      type: "components:example",
      registryDependencies: undefined,
      component: React.lazy(() => import("~/registry/miami/example/file-tree-demo")),
      source: "",
      files: ["registry/miami/example/file-tree-demo.tsx"],
      category: "undefined",
      subcategory: "undefined",
      chunks: []
    },
    "dashboard-05": {
      name: "dashboard-05",
      type: "components:block",
      registryDependencies: ["badge","breadcrumb","button","card","dropdown-menu","input","pagination","progress","separator","sheet","table","tabs","tooltip"],
      component: React.lazy(() => import("~/registry/miami/block/dashboard-05")),
      source: "__registry__/miami/block/dashboard-05.tsx",
      files: ["registry/miami/block/dashboard-05.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-05-chunk-0",
        description: "A card for an orders dashboard with a description and a button to create a new order.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-05-chunk-0")),
        file: "registry/miami/block/dashboard-05-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-1",
        description: "A stats card showing this week's total sales in USD, the percentage difference from last week, and a progress bar.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-05-chunk-1")),
        file: "registry/miami/block/dashboard-05-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-2",
        description: "A stats card showing this month's total sales in USD, the percentage difference from last month, and a progress bar.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-05-chunk-2")),
        file: "registry/miami/block/dashboard-05-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-3",
        description: "A table of recent orders showing the following columns: Customer, Type, Status, Date, and Amount.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-05-chunk-3")),
        file: "registry/miami/block/dashboard-05-chunk-3.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-05-chunk-4",
        description: "An order details card with order details, shipping information, customer information and payment information.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-05-chunk-4")),
        file: "registry/miami/block/dashboard-05-chunk-4.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-06": {
      name: "dashboard-06",
      type: "components:block",
      registryDependencies: ["badge","breadcrumb","button","card","dropdown-menu","input","sheet","table","tabs","tooltip"],
      component: React.lazy(() => import("~/registry/miami/block/dashboard-06")),
      source: "__registry__/miami/block/dashboard-06.tsx",
      files: ["registry/miami/block/dashboard-06.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-06-chunk-0",
        description: "A list of products in a table with actions. Each row has an image, name, status, price, total sales, created at and actions.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-06-chunk-0")),
        file: "registry/miami/block/dashboard-06-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-07": {
      name: "dashboard-07",
      type: "components:block",
      registryDependencies: ["badge","breadcrumb","button","card","dropdown-menu","input","pagination","progress","separator","sheet","table","tabs","tooltip"],
      component: React.lazy(() => import("~/registry/miami/block/dashboard-07")),
      source: "__registry__/miami/block/dashboard-07.tsx",
      files: ["registry/miami/block/dashboard-07.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-07-chunk-0",
        description: "A card with a form to edit the product details",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-07-chunk-0")),
        file: "registry/miami/block/dashboard-07-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-1",
        description: "A card with a form to edit the product stock and variants",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-07-chunk-1")),
        file: "registry/miami/block/dashboard-07-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-2",
        description: "A card with a form to edit the product category and subcategory",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-07-chunk-2")),
        file: "registry/miami/block/dashboard-07-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-3",
        description: "A card with a form to edit the product status",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-07-chunk-3")),
        file: "registry/miami/block/dashboard-07-chunk-3.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-4",
        description: "A card with a form to upload product images",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-07-chunk-4")),
        file: "registry/miami/block/dashboard-07-chunk-4.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-07-chunk-5",
        description: "A card with a call to action to archive the product",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-07-chunk-5")),
        file: "registry/miami/block/dashboard-07-chunk-5.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-04": {
      name: "dashboard-04",
      type: "components:block",
      registryDependencies: ["button","card","dropdown-menu","input"],
      component: React.lazy(() => import("~/registry/miami/block/dashboard-04")),
      source: "__registry__/miami/block/dashboard-04.tsx",
      files: ["registry/miami/block/dashboard-04.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-04-chunk-0",
        description: "A sidebar navigation with links to general, security, integrations, support, organizations, and advanced settings.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-04-chunk-0")),
        file: "registry/miami/block/dashboard-04-chunk-0.tsx",
        container: {
          className: "chunk-container after:right-0"
        }
      },{
        name: "dashboard-04-chunk-1",
        description: "A form to update the store name.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-04-chunk-1")),
        file: "registry/miami/block/dashboard-04-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-04-chunk-2",
        description: "A form to update the plugins directory with a checkbox to allow administrators to change the directory.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-04-chunk-2")),
        file: "registry/miami/block/dashboard-04-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-03": {
      name: "dashboard-03",
      type: "components:block",
      registryDependencies: ["badge","button","drawer","input","label","select","textarea","tooltip"],
      component: React.lazy(() => import("~/registry/miami/block/dashboard-03")),
      source: "__registry__/miami/block/dashboard-03.tsx",
      files: ["registry/miami/block/dashboard-03.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-03-chunk-0",
        description: "A settings form a configuring an AI model and messages.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-03-chunk-0")),
        file: "registry/miami/block/dashboard-03-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-03-chunk-1",
        description: "A form for sending a message to an AI chatbot. The form has a textarea and buttons to upload files and record audio.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-03-chunk-1")),
        file: "registry/miami/block/dashboard-03-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-02": {
      name: "dashboard-02",
      type: "components:block",
      registryDependencies: ["badge","button","card","dropdown-menu","input"],
      component: React.lazy(() => import("~/registry/miami/block/dashboard-02")),
      source: "__registry__/miami/block/dashboard-02.tsx",
      files: ["registry/miami/block/dashboard-02.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-02-chunk-0",
        description: "A card with a call to action",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-02-chunk-0")),
        file: "registry/miami/block/dashboard-02-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-02-chunk-1",
        description: "An empty state showing no products with a heading, description and a call to action to add a product.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-02-chunk-1")),
        file: "registry/miami/block/dashboard-02-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "dashboard-01": {
      name: "dashboard-01",
      type: "components:block",
      registryDependencies: ["button","dropdown-menu","input","sheet"],
      component: React.lazy(() => import("~/registry/miami/block/dashboard-01")),
      source: "__registry__/miami/block/dashboard-01.tsx",
      files: ["registry/miami/block/dashboard-01.tsx"],
      category: "Application",
      subcategory: "Dashboard",
      chunks: [{
        name: "dashboard-01-chunk-0",
        description: "A card showing the total revenue in USD and the percentage difference from last month.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-01-chunk-0")),
        file: "registry/miami/block/dashboard-01-chunk-0.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-1",
        description: "A card showing the total subscriptions and the percentage difference from last month.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-01-chunk-1")),
        file: "registry/miami/block/dashboard-01-chunk-1.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-2",
        description: "A card showing the total sales and the percentage difference from last month.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-01-chunk-2")),
        file: "registry/miami/block/dashboard-01-chunk-2.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-3",
        description: "A card showing the total active users and the percentage difference from last hour.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-01-chunk-3")),
        file: "registry/miami/block/dashboard-01-chunk-3.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-4",
        description: "A card showing a table of recent transactions with a link to view all transactions.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-01-chunk-4")),
        file: "registry/miami/block/dashboard-01-chunk-4.tsx",
        container: {
          className: "undefined"
        }
      },{
        name: "dashboard-01-chunk-5",
        description: "A card showing a list of recent sales with customer names and email addresses.",
        component: React.lazy(() => import("~/registry/miami/block/dashboard-01-chunk-5")),
        file: "registry/miami/block/dashboard-01-chunk-5.tsx",
        container: {
          className: "undefined"
        }
      }]
    },
    "authentication-01": {
      name: "authentication-01",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/miami/block/authentication-01")),
      source: "__registry__/miami/block/authentication-01.tsx",
      files: ["registry/miami/block/authentication-01.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "authentication-02": {
      name: "authentication-02",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/miami/block/authentication-02")),
      source: "__registry__/miami/block/authentication-02.tsx",
      files: ["registry/miami/block/authentication-02.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "authentication-03": {
      name: "authentication-03",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/miami/block/authentication-03")),
      source: "__registry__/miami/block/authentication-03.tsx",
      files: ["registry/miami/block/authentication-03.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "authentication-04": {
      name: "authentication-04",
      type: "components:block",
      registryDependencies: ["button","card","input","label"],
      component: React.lazy(() => import("~/registry/miami/block/authentication-04")),
      source: "__registry__/miami/block/authentication-04.tsx",
      files: ["registry/miami/block/authentication-04.tsx"],
      category: "Authentication",
      subcategory: "Login",
      chunks: []
    },
    "chart-area-axes": {
      name: "chart-area-axes",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-axes")),
      source: "__registry__/miami/block/chart-area-axes.tsx",
      files: ["registry/miami/block/chart-area-axes.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-default": {
      name: "chart-area-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-default")),
      source: "__registry__/miami/block/chart-area-default.tsx",
      files: ["registry/miami/block/chart-area-default.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-gradient": {
      name: "chart-area-gradient",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-gradient")),
      source: "__registry__/miami/block/chart-area-gradient.tsx",
      files: ["registry/miami/block/chart-area-gradient.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-icons": {
      name: "chart-area-icons",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-icons")),
      source: "__registry__/miami/block/chart-area-icons.tsx",
      files: ["registry/miami/block/chart-area-icons.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-interactive": {
      name: "chart-area-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-interactive")),
      source: "__registry__/miami/block/chart-area-interactive.tsx",
      files: ["registry/miami/block/chart-area-interactive.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-legend": {
      name: "chart-area-legend",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-legend")),
      source: "__registry__/miami/block/chart-area-legend.tsx",
      files: ["registry/miami/block/chart-area-legend.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-linear": {
      name: "chart-area-linear",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-linear")),
      source: "__registry__/miami/block/chart-area-linear.tsx",
      files: ["registry/miami/block/chart-area-linear.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-stacked-expand": {
      name: "chart-area-stacked-expand",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-stacked-expand")),
      source: "__registry__/miami/block/chart-area-stacked-expand.tsx",
      files: ["registry/miami/block/chart-area-stacked-expand.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-stacked": {
      name: "chart-area-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-stacked")),
      source: "__registry__/miami/block/chart-area-stacked.tsx",
      files: ["registry/miami/block/chart-area-stacked.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-area-step": {
      name: "chart-area-step",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-area-step")),
      source: "__registry__/miami/block/chart-area-step.tsx",
      files: ["registry/miami/block/chart-area-step.tsx"],
      category: "Charts",
      subcategory: "Area",
      chunks: []
    },
    "chart-bar-active": {
      name: "chart-bar-active",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-active")),
      source: "__registry__/miami/block/chart-bar-active.tsx",
      files: ["registry/miami/block/chart-bar-active.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-default": {
      name: "chart-bar-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-default")),
      source: "__registry__/miami/block/chart-bar-default.tsx",
      files: ["registry/miami/block/chart-bar-default.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-horizontal": {
      name: "chart-bar-horizontal",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-horizontal")),
      source: "__registry__/miami/block/chart-bar-horizontal.tsx",
      files: ["registry/miami/block/chart-bar-horizontal.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-interactive": {
      name: "chart-bar-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-interactive")),
      source: "__registry__/miami/block/chart-bar-interactive.tsx",
      files: ["registry/miami/block/chart-bar-interactive.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-label-custom": {
      name: "chart-bar-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-label-custom")),
      source: "__registry__/miami/block/chart-bar-label-custom.tsx",
      files: ["registry/miami/block/chart-bar-label-custom.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-label": {
      name: "chart-bar-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-label")),
      source: "__registry__/miami/block/chart-bar-label.tsx",
      files: ["registry/miami/block/chart-bar-label.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-mixed": {
      name: "chart-bar-mixed",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-mixed")),
      source: "__registry__/miami/block/chart-bar-mixed.tsx",
      files: ["registry/miami/block/chart-bar-mixed.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-multiple": {
      name: "chart-bar-multiple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-multiple")),
      source: "__registry__/miami/block/chart-bar-multiple.tsx",
      files: ["registry/miami/block/chart-bar-multiple.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-negative": {
      name: "chart-bar-negative",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-negative")),
      source: "__registry__/miami/block/chart-bar-negative.tsx",
      files: ["registry/miami/block/chart-bar-negative.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-bar-stacked": {
      name: "chart-bar-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-bar-stacked")),
      source: "__registry__/miami/block/chart-bar-stacked.tsx",
      files: ["registry/miami/block/chart-bar-stacked.tsx"],
      category: "Charts",
      subcategory: "Bar",
      chunks: []
    },
    "chart-line-default": {
      name: "chart-line-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-default")),
      source: "__registry__/miami/block/chart-line-default.tsx",
      files: ["registry/miami/block/chart-line-default.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-dots-colors": {
      name: "chart-line-dots-colors",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-dots-colors")),
      source: "__registry__/miami/block/chart-line-dots-colors.tsx",
      files: ["registry/miami/block/chart-line-dots-colors.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-dots-custom": {
      name: "chart-line-dots-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-dots-custom")),
      source: "__registry__/miami/block/chart-line-dots-custom.tsx",
      files: ["registry/miami/block/chart-line-dots-custom.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-dots": {
      name: "chart-line-dots",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-dots")),
      source: "__registry__/miami/block/chart-line-dots.tsx",
      files: ["registry/miami/block/chart-line-dots.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-interactive": {
      name: "chart-line-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-interactive")),
      source: "__registry__/miami/block/chart-line-interactive.tsx",
      files: ["registry/miami/block/chart-line-interactive.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-label-custom": {
      name: "chart-line-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-label-custom")),
      source: "__registry__/miami/block/chart-line-label-custom.tsx",
      files: ["registry/miami/block/chart-line-label-custom.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-label": {
      name: "chart-line-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-label")),
      source: "__registry__/miami/block/chart-line-label.tsx",
      files: ["registry/miami/block/chart-line-label.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-linear": {
      name: "chart-line-linear",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-linear")),
      source: "__registry__/miami/block/chart-line-linear.tsx",
      files: ["registry/miami/block/chart-line-linear.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-multiple": {
      name: "chart-line-multiple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-multiple")),
      source: "__registry__/miami/block/chart-line-multiple.tsx",
      files: ["registry/miami/block/chart-line-multiple.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-line-step": {
      name: "chart-line-step",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-line-step")),
      source: "__registry__/miami/block/chart-line-step.tsx",
      files: ["registry/miami/block/chart-line-step.tsx"],
      category: "Charts",
      subcategory: "Line",
      chunks: []
    },
    "chart-pie-donut-active": {
      name: "chart-pie-donut-active",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-donut-active")),
      source: "__registry__/miami/block/chart-pie-donut-active.tsx",
      files: ["registry/miami/block/chart-pie-donut-active.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-donut-text": {
      name: "chart-pie-donut-text",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-donut-text")),
      source: "__registry__/miami/block/chart-pie-donut-text.tsx",
      files: ["registry/miami/block/chart-pie-donut-text.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-donut": {
      name: "chart-pie-donut",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-donut")),
      source: "__registry__/miami/block/chart-pie-donut.tsx",
      files: ["registry/miami/block/chart-pie-donut.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-interactive": {
      name: "chart-pie-interactive",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-interactive")),
      source: "__registry__/miami/block/chart-pie-interactive.tsx",
      files: ["registry/miami/block/chart-pie-interactive.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-label-custom": {
      name: "chart-pie-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-label-custom")),
      source: "__registry__/miami/block/chart-pie-label-custom.tsx",
      files: ["registry/miami/block/chart-pie-label-custom.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-label-list": {
      name: "chart-pie-label-list",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-label-list")),
      source: "__registry__/miami/block/chart-pie-label-list.tsx",
      files: ["registry/miami/block/chart-pie-label-list.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-label": {
      name: "chart-pie-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-label")),
      source: "__registry__/miami/block/chart-pie-label.tsx",
      files: ["registry/miami/block/chart-pie-label.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-legend": {
      name: "chart-pie-legend",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-legend")),
      source: "__registry__/miami/block/chart-pie-legend.tsx",
      files: ["registry/miami/block/chart-pie-legend.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-separator-none": {
      name: "chart-pie-separator-none",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-separator-none")),
      source: "__registry__/miami/block/chart-pie-separator-none.tsx",
      files: ["registry/miami/block/chart-pie-separator-none.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-simple": {
      name: "chart-pie-simple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-simple")),
      source: "__registry__/miami/block/chart-pie-simple.tsx",
      files: ["registry/miami/block/chart-pie-simple.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-pie-stacked": {
      name: "chart-pie-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-pie-stacked")),
      source: "__registry__/miami/block/chart-pie-stacked.tsx",
      files: ["registry/miami/block/chart-pie-stacked.tsx"],
      category: "Charts",
      subcategory: "Pie",
      chunks: []
    },
    "chart-radar-default": {
      name: "chart-radar-default",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-default")),
      source: "__registry__/miami/block/chart-radar-default.tsx",
      files: ["registry/miami/block/chart-radar-default.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-dots": {
      name: "chart-radar-dots",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-dots")),
      source: "__registry__/miami/block/chart-radar-dots.tsx",
      files: ["registry/miami/block/chart-radar-dots.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-circle-fill": {
      name: "chart-radar-grid-circle-fill",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-grid-circle-fill")),
      source: "__registry__/miami/block/chart-radar-grid-circle-fill.tsx",
      files: ["registry/miami/block/chart-radar-grid-circle-fill.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-circle-no-lines": {
      name: "chart-radar-grid-circle-no-lines",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-grid-circle-no-lines")),
      source: "__registry__/miami/block/chart-radar-grid-circle-no-lines.tsx",
      files: ["registry/miami/block/chart-radar-grid-circle-no-lines.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-circle": {
      name: "chart-radar-grid-circle",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-grid-circle")),
      source: "__registry__/miami/block/chart-radar-grid-circle.tsx",
      files: ["registry/miami/block/chart-radar-grid-circle.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-custom": {
      name: "chart-radar-grid-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-grid-custom")),
      source: "__registry__/miami/block/chart-radar-grid-custom.tsx",
      files: ["registry/miami/block/chart-radar-grid-custom.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-fill": {
      name: "chart-radar-grid-fill",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-grid-fill")),
      source: "__registry__/miami/block/chart-radar-grid-fill.tsx",
      files: ["registry/miami/block/chart-radar-grid-fill.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-grid-none": {
      name: "chart-radar-grid-none",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-grid-none")),
      source: "__registry__/miami/block/chart-radar-grid-none.tsx",
      files: ["registry/miami/block/chart-radar-grid-none.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-icons": {
      name: "chart-radar-icons",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-icons")),
      source: "__registry__/miami/block/chart-radar-icons.tsx",
      files: ["registry/miami/block/chart-radar-icons.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-label-custom": {
      name: "chart-radar-label-custom",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-label-custom")),
      source: "__registry__/miami/block/chart-radar-label-custom.tsx",
      files: ["registry/miami/block/chart-radar-label-custom.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-legend": {
      name: "chart-radar-legend",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-legend")),
      source: "__registry__/miami/block/chart-radar-legend.tsx",
      files: ["registry/miami/block/chart-radar-legend.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-lines-only": {
      name: "chart-radar-lines-only",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-lines-only")),
      source: "__registry__/miami/block/chart-radar-lines-only.tsx",
      files: ["registry/miami/block/chart-radar-lines-only.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-multiple": {
      name: "chart-radar-multiple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-multiple")),
      source: "__registry__/miami/block/chart-radar-multiple.tsx",
      files: ["registry/miami/block/chart-radar-multiple.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radar-radius": {
      name: "chart-radar-radius",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radar-radius")),
      source: "__registry__/miami/block/chart-radar-radius.tsx",
      files: ["registry/miami/block/chart-radar-radius.tsx"],
      category: "Charts",
      subcategory: "Radar",
      chunks: []
    },
    "chart-radial-grid": {
      name: "chart-radial-grid",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radial-grid")),
      source: "__registry__/miami/block/chart-radial-grid.tsx",
      files: ["registry/miami/block/chart-radial-grid.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-label": {
      name: "chart-radial-label",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radial-label")),
      source: "__registry__/miami/block/chart-radial-label.tsx",
      files: ["registry/miami/block/chart-radial-label.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-shape": {
      name: "chart-radial-shape",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radial-shape")),
      source: "__registry__/miami/block/chart-radial-shape.tsx",
      files: ["registry/miami/block/chart-radial-shape.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-simple": {
      name: "chart-radial-simple",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radial-simple")),
      source: "__registry__/miami/block/chart-radial-simple.tsx",
      files: ["registry/miami/block/chart-radial-simple.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-stacked": {
      name: "chart-radial-stacked",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radial-stacked")),
      source: "__registry__/miami/block/chart-radial-stacked.tsx",
      files: ["registry/miami/block/chart-radial-stacked.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
    "chart-radial-text": {
      name: "chart-radial-text",
      type: "components:block",
      registryDependencies: ["card","chart"],
      component: React.lazy(() => import("~/registry/miami/block/chart-radial-text")),
      source: "__registry__/miami/block/chart-radial-text.tsx",
      files: ["registry/miami/block/chart-radial-text.tsx"],
      category: "Charts",
      subcategory: "Radial",
      chunks: []
    },
  },
}
