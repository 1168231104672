import {
   Alert,
   AlertDescription,
   AlertTitle,
} from '~/registry/miami/ui/alert'

interface CalloutProps {
   icon?: string
   title?: string
   children?: React.ReactNode
}

export function Callout({ title, children, icon, ...props }: CalloutProps) {
   return (
      <Alert {...props}>
         {icon && <span className="mr-4 text-2xl">{icon}</span>}
         {title && <AlertTitle>{title}</AlertTitle>}
         <AlertDescription>{children}</AlertDescription>
      </Alert>
   )
}
